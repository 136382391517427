import _ from "lodash";
import { Status } from "../constants/Status";

export const displayStatus = (status: Status = Status.Active): string => {
  const statusMap: Record<Status, string> = {
    [Status.Active]: "啟用",
    [Status.Inactive]: "停用",
  };

  return _.get(statusMap, status, "");
};
