import { Modal } from "antd";
import React, { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { messageTemplateSlice } from "../../store/messageTemplateSlice";
import { displayStatus } from "../../utils/displayStatus";
import { formatTime } from "../../utils/formatTime";
import { Label, Value, Wrapper } from "../common/displayElement";

export const DisplayTemplateModal: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const displayTemplate = useAppSelector(
    (state) => state.messageTemplate.displayTemplate
  );

  const { displayModalVisible } = useAppSelector(
    (state) => state.messageTemplate.modal
  );

  const handleOk = () => {
    dispatch(messageTemplateSlice.actions.toggleDisplayModal());
  };

  const handleClose = () => {
    dispatch(messageTemplateSlice.actions.setDisplayTemplate(null));
  };

  return (
    <Modal
      title="範本內容"
      visible={displayModalVisible}
      onOk={handleOk}
      onCancel={handleOk}
      cancelButtonProps={{ style: { display: "none" } }}
      okText={"返回"}
      destroyOnClose={true}
      afterClose={handleClose}
    >
      <Wrapper>
        <Label>狀態：</Label>
        <Value>{displayStatus(displayTemplate?.status)}</Value>
      </Wrapper>
      <Wrapper>
        <Label>範本名稱：</Label>
        <Value>{displayTemplate?.name}</Value>
      </Wrapper>
      <Wrapper>
        <Label>內容：</Label>
        <Value>{displayTemplate?.content}</Value>
      </Wrapper>
      <Wrapper>
        <Label>更新人員：</Label>
        <Value>{displayTemplate?.updatedUsername}</Value>
      </Wrapper>
      <Wrapper>
        <Label>更新時間：</Label>
        <Value>{formatTime(displayTemplate?.updatedAt)}</Value>
      </Wrapper>
    </Modal>
  );
};
