import { Modal } from "antd";
import React, { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { jiraReportSlice } from "../../store/jiraReportSlice";
import { formatTime } from "../../utils/formatTime";
import { Label, Value, Wrapper } from "../common/displayElement";

export const DisplayJiraReportModal: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const displayJiraReport = useAppSelector(
    (state) => state.jiraReport.displayJiraReport
  );

  const { displayModalVisible } = useAppSelector(
    (state) => state.jiraReport.modal
  );

  const handleOk = () => {
    dispatch(jiraReportSlice.actions.toggleDisplayModal());
  };

  const handleClose = () => {
    dispatch(jiraReportSlice.actions.setDisplayJiraReport(null));
  };

  return (
    <Modal
      title="發送通知明細"
      visible={displayModalVisible}
      onOk={handleOk}
      onCancel={handleOk}
      cancelButtonProps={{ style: { display: "none" } }}
      okText={"返回"}
      destroyOnClose={true}
      afterClose={handleClose}
    >
      <Wrapper>
        <Label>Jira 單號：</Label>
        <Value>{displayJiraReport?.key}</Value>
      </Wrapper>
      <Wrapper>
        <Label>標題：</Label>
        <Value>{displayJiraReport?.title}</Value>
      </Wrapper>
      <Wrapper>
        <Label>交談名稱：</Label>
        <Value>{displayJiraReport?.channelName}</Value>
      </Wrapper>
      <Wrapper>
        <Label>開單人員：</Label>
        <Value>{displayJiraReport?.createdUserName}</Value>
      </Wrapper>
      <Wrapper>
        <Label>新增日期：</Label>
        <Value>{formatTime(displayJiraReport?.createdAt)}</Value>
      </Wrapper>
    </Modal>
  );
};
