import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchParams } from "../types/api";
import {
  GetMessageTemplatesResponse,
  GetActiveMessageTemplatesResponse,
  MessageTemplate,
  MessageTemplateState,
} from "../types/MessageTemplate";

const initialState: MessageTemplateState = {
  modal: {
    createModalVisible: false,
    editModalVisible: false,
    displayModalVisible: false,
  },
  messageTemplates: {
    list: [],
    fetching: false,
    total: 0,
    totalPages: 0,
    page: 0,
  },
  activeMessageTemplates: [],
  editingTemplate: null,
  displayTemplate: null,
  searchParams: {
    page: 1,
    filter: "",
    orderBy: "",
  },
};

export const messageTemplateSlice = createSlice({
  name: "messageTemplate",
  initialState,
  reducers: {
    fetchingMessageTemplates: (state) => {
      state.messageTemplates.fetching = true;
    },
    fetchMessageTemplatesSuccess: (
      state,
      action: PayloadAction<GetMessageTemplatesResponse>
    ) => {
      state.messageTemplates.fetching = false;
      state.messageTemplates.list = action.payload.messageTemplates;
      state.messageTemplates.total = action.payload.count;
      state.messageTemplates.totalPages = action.payload.totalPages;
    },
    fetchMessageTemplatesFail: (state) => {
      state.messageTemplates.fetching = false;
      state.messageTemplates.list = [];
    },
    fetchActiveMessageTemplatesSuccess: (
      state,
      action: PayloadAction<GetActiveMessageTemplatesResponse[]>
    ) => {
      state.activeMessageTemplates = action.payload;
    },
    fetchActiveMessageTemplatesFail: (state) => {
      state.activeMessageTemplates = [];
    },
    setEditingTemplate: (
      state,
      action: PayloadAction<Nullable<MessageTemplate>>
    ) => {
      state.editingTemplate = action.payload;
    },
    setDisplayTemplate: (
      state,
      action: PayloadAction<Nullable<MessageTemplate>>
    ) => {
      state.displayTemplate = action.payload;
    },
    toggleCreateModal: (state) => {
      state.modal.createModalVisible = !state.modal.createModalVisible;
    },
    toggleEditModal: (state) => {
      state.modal.editModalVisible = !state.modal.editModalVisible;
    },
    toggleDisplayModal: (state) => {
      state.modal.displayModalVisible = !state.modal.displayModalVisible;
    },
    changeCurrentPage: (state, action: PayloadAction<number>) => {
      state.messageTemplates.page = action.payload;
    },
    setSearchParams: (state, action: PayloadAction<SearchParams>) => {
      state.searchParams = action.payload;
    },
  },
});
