import { Modal, Tree } from "antd";
import React, { FunctionComponent } from "react";
import { permissionTreeData } from "../../constants/permissionTreeData";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { userSlice } from "../../store/userSlice";
import { convertPermissionsToKeys } from "../../utils/convertPermissionsToKeys";
import { displayRole } from "../../utils/displayRole";
import { displayStatus } from "../../utils/displayStatus";
import { formatTime } from "../../utils/formatTime";
import { Label, Value, Wrapper } from "../common/displayElement";

export const DisplayUserModal: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const displayUser = useAppSelector((state) => state.user.displayUser);

  const { displayModalVisible } = useAppSelector((state) => state.user.modal);

  const handleOk = () => {
    dispatch(userSlice.actions.toggleDisplayModal());
  };

  const handleClose = () => {
    dispatch(userSlice.actions.setDisplayUser(null));
  };

  return (
    <Modal
      title="查詢使用者"
      visible={displayModalVisible}
      onOk={handleOk}
      onCancel={handleOk}
      cancelButtonProps={{ style: { display: "none" } }}
      okText={"返回"}
      destroyOnClose={true}
      afterClose={handleClose}
    >
      <Wrapper>
        <Label>使用者帳號：</Label>
        <Value>{displayUser?.idFromOkta}</Value>
      </Wrapper>
      <Wrapper>
        <Label>狀態：</Label>
        <Value>{displayStatus(displayUser?.status)}</Value>
      </Wrapper>
      <Wrapper>
        <Label>角色：</Label>
        <Value>{displayRole(displayUser?.role)}</Value>
      </Wrapper>
      <Wrapper>
        <Label>權限：</Label>
        <Tree
          checkable={true}
          treeData={permissionTreeData}
          checkedKeys={convertPermissionsToKeys(displayUser?.permissions)}
        />
      </Wrapper>
      <Wrapper>
        <Label>更新人員：</Label>
        <Value>{displayUser?.updatedUsername}</Value>
      </Wrapper>
      <Wrapper>
        <Label>更新時間：</Label>
        <Value>{formatTime(displayUser?.updatedAt)}</Value>
      </Wrapper>
    </Modal>
  );
};
