import { Button, Form, Input } from "antd";
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { useMount } from "react-use";
import styled from "styled-components";
import { getNotifications } from "../../api/notificationAPI";
import { Function } from "../../constants/Function";
import { Permission } from "../../constants/Permission";
import { usePermission } from "../../hooks/usePermissions";
import { notificationSlice } from "../../store/notificationSlice";
import { formatFuzzySearch } from "../../utils/formatFuzzySearch";
import { formatSearchFilter } from "../../utils/formatSearchFilter";
import { getEmailsHeadOffices } from "../../api/headOfficeAPI";

export const NotificationSearchCriteria: FunctionComponent = () => {
  const notificationManagementPermission = usePermission(Function.Notification);
  const [form] = Form.useForm<{
    status?: number;
    searchText?: string;
    groupName?: string;
  }>();
  const dispatch = useDispatch();
  const handleCreateNotification = () => {
    dispatch(notificationSlice.actions.toggleCreateModal());
  };

  const fetch = () => {
    const values = form.getFieldsValue();
    const filter = formatSearchFilter([
      formatFuzzySearch(["name"], values.searchText),
      formatFuzzySearch(["groupname"], values.groupName),
    ]);

    dispatch(getNotifications(1, filter));
  };

  useMount(() => {
    fetch();
    dispatch(getEmailsHeadOffices());
  });

  return (
    <ActionWrapper>
      <Form form={form} layout={"inline"}>
        <Form.Item label="通知名稱" name="searchText">
          <Input
            allowClear
            placeholder={"請輸入通知名稱"}
            style={{ width: 200 }}
          />
        </Form.Item>
        <Form.Item label="分群名稱" name="groupName">
          <Input
            allowClear
            placeholder={"請輸入分群名稱"}
            style={{ width: 200 }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={fetch} htmlType="submit">
            搜尋
          </Button>
        </Form.Item>
      </Form>
      {notificationManagementPermission.includes(Permission.Add) && (
        <Button type={"primary"} onClick={handleCreateNotification}>
          新增通知
        </Button>
      )}
    </ActionWrapper>
  );
};

const ActionWrapper = styled.div`
  margin: 0 0 24px 0;
  display: flex;
  justify-content: space-between;
`;
