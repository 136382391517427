import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { CreateSLAModal } from "./CreateSLAModal";
import { DisplaySLAModal } from "./DisplaySLAModal";
import { EditSLAModal } from "./EditSLAModal";
import { SLASearchCriteria } from "./SLASearchCriteria";
import { SLATable } from "./SLATable";

export const SLAManagement: FunctionComponent = () => {
  return (
    <Wrapper>
      <SLASearchCriteria />
      <SLATable />
      <CreateSLAModal />
      <EditSLAModal />
      <DisplaySLAModal />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
