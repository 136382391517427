import { useCallback, useEffect, useState } from "react";
import { AppContext } from "../core/api/AppContext";
import { GetGroupResponse } from "../types/Group";

export const useActiveGroups = () => {
  const [groups, setGroups] = useState<GetGroupResponse[]>([]);

  const getActiveGroups = useCallback(() => {

    AppContext.ApiExecutor.getActiveGroups()
      .then(({ data }) => {
        setGroups(data);
      })
      .catch((error) => {console.warn(error)})
  }, [groups]);

  useEffect(() => getActiveGroups(), []);

  return { groups };
};
