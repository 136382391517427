import { Dropdown, Layout, Menu, MenuProps, Space } from "antd";
import Cookies from "js-cookie";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { goToPage } from "../../goToPage";
import { useAppSelector } from "../../store/hooks";

const { Header } = Layout;

export const AppHeader: FunctionComponent = () => {
  const { rolePermission } = useAppSelector((state) => state.auth);

  const onClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "signOut") {
      Cookies.set("userToken", "");
      goToPage("/login");
    }
  };

  const menu = (
    <Menu onClick={onClick}>
      <Menu.Item danger={true} key={"signOut"}>
        Sign out
      </Menu.Item>
    </Menu>
  );

  return (
    <Header id={"header"}>
      <HeaderWrapper>
        <Logo>Message Hub</Logo>
        <Dropdown
          overlay={menu}
          trigger={["click", "hover"]}
          overlayStyle={{ width: "150" }}
          getPopupContainer={() =>
            document.getElementById("header") || document.body
          }
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>{rolePermission?.idFromOkta}</Space>
          </a>
        </Dropdown>
      </HeaderWrapper>
    </Header>
  );
};

const Logo = styled.div``;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: white;
`;
