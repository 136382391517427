import { Button, Form, Input, Select } from "antd";
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { useMount } from "react-use";
import styled from "styled-components";
import { getChannels } from "../../api/channelAPI";
import { getHeadOffices } from "../../api/headOfficeAPI";
import { useAppSelector } from "../../store/hooks";
import { formatFuzzySearch } from "../../utils/formatFuzzySearch";
import { formatSearchFilter } from "../../utils/formatSearchFilter";
import { formatSearchHeadOffice } from "../../utils/formatSearchHeadOffice";
import { formatSearchStatus } from "../../utils/formatSearchStatus";

export const ChannelSearchCriteria: FunctionComponent = () => {
  const [form] = Form.useForm<{
    status?: number;
    headOffice?: string;
    searchText?: string;
  }>();
  const dispatch = useDispatch();
  const headOffice = useAppSelector((state) => state.headOffice);

  const fetch = () => {
    const values = form.getFieldsValue();
    const filter = formatSearchFilter([
      formatFuzzySearch(["name"], values.searchText),
      formatSearchHeadOffice(values.headOffice),
      formatSearchStatus(values.status),
    ]);

    dispatch(getChannels(1, filter));
  };

  useMount(() => {
    fetch();
    dispatch(getHeadOffices());
  });

  return (
    <ActionWrapper>
      <Form form={form} layout={"inline"}>
        <Form.Item label="交談名稱" name="searchText">
          <Input
            allowClear
            placeholder={"請輸入交談名稱"}
            style={{ width: 200 }}
          />
        </Form.Item>
        <Form.Item label="總社" name="headOffice">
          <Select allowClear placeholder="所有總社" style={{ width: 200 }}>
            {headOffice.list.map((office) => (
              <Select.Option value={office} key={office}>
                {office}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="狀態" name="status">
          <Select allowClear placeholder="所有狀態" style={{ width: 100 }}>
            <Select.Option value={0}>啟用</Select.Option>
            <Select.Option value={1}>停用</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={fetch} htmlType="submit">
            搜尋
          </Button>
        </Form.Item>
      </Form>
    </ActionWrapper>
  );
};

const ActionWrapper = styled.div`
  margin: 0 0 24px 0;
  display: flex;
  justify-content: space-between;
`;
