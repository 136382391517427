import { Moment } from "moment";

const timeFormat = "YYYY-MM-DDTHH:mm:ss";
export type Duration = undefined | null | Moment[];

export const formatSearchDuration = (
  field: string,
  duration: Duration
): string => {
  if (!duration) {
    return "";
  }
  const [startAt, endAt] = [
    duration[0].startOf("day").subtract(8, "hours").format(timeFormat),
    duration[1].endOf("day").subtract(8, "hours").format(timeFormat),
  ];

  return `${field}>=${startAt},${field}<=${endAt}`;
};
