import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchParams } from "../types/api";
import { GetGroupsResponse, Group, GroupState } from "../types/Group";

const initialState: GroupState = {
  modal: {
    createModalVisible: false,
    editModalVisible: false,
    displayModalVisible: false,
  },
  groups: {
    list: [],
    fetching: false,
    total: 0,
    totalPages: 0,
    page: 0,
  },
  editingGroup: null,
  displayGroup: null,
  searchParams: {
    page: 1,
    filter: "",
    orderBy: "",
  },
};

export const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    fetchingGroups: (state) => {
      state.groups.fetching = true;
    },
    fetchGroupsSuccess: (state, action: PayloadAction<GetGroupsResponse>) => {
      state.groups.fetching = false;
      state.groups.list = action.payload.groups;
      state.groups.total = action.payload.count;
      state.groups.totalPages = action.payload.totalPages;
    },
    fetchGroupsFail: (state) => {
      state.groups.fetching = false;
      state.groups.list = [];
    },
    setEditingGroup: (state, action: PayloadAction<Nullable<Group>>) => {
      state.editingGroup = action.payload;
    },
    setDisplayGroup: (state, action: PayloadAction<Nullable<Group>>) => {
      state.displayGroup = action.payload;
    },
    toggleCreateModal: (state) => {
      state.modal.createModalVisible = !state.modal.createModalVisible;
    },
    toggleEditModal: (state) => {
      state.modal.editModalVisible = !state.modal.editModalVisible;
    },
    toggleDisplayModal: (state) => {
      state.modal.displayModalVisible = !state.modal.displayModalVisible;
    },
    changeCurrentPage: (state, action: PayloadAction<number>) => {
      state.groups.page = action.payload;
    },
    setSearchParams: (state, action: PayloadAction<SearchParams>) => {
      state.searchParams = action.payload;
    },
  },
});
