import { AppContext } from "../core/api/AppContext";
import { headOfficeSlice } from "../store/headOfficeSlice";
import { AppDispatch } from "../store/store";

export const getHeadOffices = () => (dispatch: AppDispatch) => {
  AppContext.ApiExecutor.getOffices()
    .then(({ data }) => {
      dispatch(headOfficeSlice.actions.setList(data));
    })
    .catch(() => {
      dispatch(headOfficeSlice.actions.setList([]));
    });
};

export const getEmailsHeadOffices = () => (dispatch: AppDispatch) => {
  AppContext.ApiExecutor.getEmailsOffices()
    .then(({ data }) => {
      dispatch(headOfficeSlice.actions.setEmailsList(data));
    })
    .catch(() => {
      dispatch(headOfficeSlice.actions.setEmailsList([]));
    });
};
