import { Layout } from "antd";
import React, { FunctionComponent } from "react";
import { AppHeader } from "./AppHeader";
import { AppSider } from "./AppSider";

const { Content } = Layout;

export const AppLayout: FunctionComponent = ({ children }) => {
  return (
    <Layout>
      <AppHeader />
      <Layout>
        <AppSider />
        <Layout style={{ padding: "24px" }}>
          <Content>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
