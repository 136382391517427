import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Modal, notification, Table } from "antd";
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import {
  getMessageTemplates,
  refreshMessageTemplates,
} from "../../api/messageTemplateAPI";
import { Function } from "../../constants/Function";
import { pageSize } from "../../constants/pagination";
import { Permission } from "../../constants/Permission";
import { AppContext } from "../../core/api/AppContext";
import { usePermission } from "../../hooks/usePermissions";
import { useAppSelector } from "../../store/hooks";
import { messageTemplateSlice } from "../../store/messageTemplateSlice";
import { MessageTemplate } from "../../types/MessageTemplate";
import { displayStatus } from "../../utils/displayStatus";
import { formatTime } from "../../utils/formatTime";
import { getOrderBy } from "../../utils/getOrderBy";
import { showTotalData } from "../../utils/showTotalData";
import { ResourceName } from "../common/ResourceName";
import { TableActionWrapper } from "../common/TableActionWrapper";

export const TemplateTable: FunctionComponent = () => {
  const templateManagementPermission = usePermission(Function.MessageTemplate);
  const dispatch = useDispatch();
  const { messageTemplates, searchParams } = useAppSelector(
    (state) => state.messageTemplate
  );

  const handleEdit = (messageTemplate: MessageTemplate): void => {
    dispatch(messageTemplateSlice.actions.setEditingTemplate(messageTemplate));
    dispatch(messageTemplateSlice.actions.toggleEditModal());
  };

  const handleDelete = (messageTemplate: MessageTemplate): void => {
    Modal.confirm({
      title: "刪除範本",
      icon: <ExclamationCircleOutlined />,
      content: `確定要刪除範本「${messageTemplate.name}」？`,
      onOk() {
        AppContext.ApiExecutor.deleteMessageTemplate(messageTemplate.id)
          .then(() => {
            notification.success({
              message: "刪除成功",
              placement: "bottomRight",
            });
            dispatch(refreshMessageTemplates());
          })
          .catch(() => {
            notification.warn({
              message: "刪除失敗",
              placement: "bottomRight",
            });
          });
      },
    });
  };

  const handleDisplay = (messageTemplate: MessageTemplate): void => {
    dispatch(messageTemplateSlice.actions.setDisplayTemplate(messageTemplate));
    dispatch(messageTemplateSlice.actions.toggleDisplayModal());
  };

  const onFilterChange = (
    pagination: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<MessageTemplate> | SorterResult<MessageTemplate>[]
  ) => {
    const orderBy = getOrderBy<MessageTemplate>(sorter);
    dispatch(
      getMessageTemplates(pagination.current ?? 1, searchParams.filter, orderBy)
    );
  };

  return (
    <Table<MessageTemplate>
      tableLayout={"auto"}
      loading={messageTemplates.fetching}
      dataSource={messageTemplates.list}
      scroll={{ y: 800, x: 700 }}
      onChange={onFilterChange}
      pagination={{
        pageSize,
        showSizeChanger: false,
        total: messageTemplates.total,
        current: messageTemplates.page,
        showTotal: () => showTotalData(messageTemplates.total),
      }}
      rowKey={"id"}
    >
      <Table.Column<MessageTemplate>
        sorter={true}
        title="名稱"
        dataIndex="name"
        width={150}
        render={(name: string, data) => (
          <ResourceName onClick={() => handleDisplay(data)}>
            {name}
          </ResourceName>
        )}
      />
      <Table.Column
        sorter={true}
        title="內容"
        dataIndex="content"
        render={(content: string) =>
          content.length > 20 ? `${content.slice(0, 20)} ...` : content
        }
      />
      <Table.Column
        sorter={true}
        title="更新人員"
        dataIndex="updatedUsername"
        width={150}
      />
      <Table.Column
        sorter={true}
        title="更新時間"
        dataIndex="updatedAt"
        width={200}
        align={"center"}
        render={(e: string) => formatTime(e)}
      />
      <Table.Column<MessageTemplate>
        sorter={true}
        title="狀態"
        dataIndex="status"
        align={"center"}
        width={100}
        render={(status: number) => displayStatus(status)}
      />
      <Table.Column<MessageTemplate>
        title="操作"
        dataIndex="id"
        align={"center"}
        width={100}
        render={(id, data) => {
          return (
            <TableActionWrapper>
              {templateManagementPermission.includes(Permission.Edit) && (
                <EditOutlined onClick={() => handleEdit(data)} />
              )}
              {templateManagementPermission.includes(Permission.Delete) && (
                <DeleteOutlined onClick={() => handleDelete(data)} />
              )}
            </TableActionWrapper>
          );
        }}
      />
    </Table>
  );
};
