import _ from "lodash";
import { SendStatus } from "../constants/SendStatus";

export const displaySendStatus = (
  sendStatus: SendStatus = SendStatus.Sent
): string => {
  const sendStatusMap: Record<SendStatus, string> = {
    [SendStatus.Sent]: "已完成",
    [SendStatus.Saved]: "儲存",
    [SendStatus.Failed]: "失敗",
    [SendStatus.Deleted]: "刪除",
  };

  return _.get(sendStatusMap, sendStatus, "已完成");
};
