import { Dispatch } from "react";
import { AppContext } from "../core/api/AppContext";
import { notificationReportSlice } from "../store/notificationReportSlice";
import { AppDispatch, RootState } from "../store/store";

export const getNotificationReports =
  (page: number, filter?: string, orderBy?: string) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const searchFilter =
      filter !== undefined
        ? filter
        : getState().notificationReport.searchParams.filter;
    const searchOrderBy =
      orderBy !== undefined
        ? orderBy
        : getState().notificationReport.searchParams.orderBy;

    dispatch(
      notificationReportSlice.actions.setSearchParams({
        page,
        filter: searchFilter,
        orderBy: searchOrderBy,
      })
    );

    dispatch(notificationReportSlice.actions.fetchingNotificationReports());

    AppContext.ApiExecutor.getNotificationReports({
      page,
      filter: searchFilter,
      orderBy: searchOrderBy,
    })
      .then(({ data }) => {
        dispatch(
          notificationReportSlice.actions.fetchNotificationReportsSuccess(data)
        );
      })
      .catch(() => {
        notificationReportSlice.actions.fetchNotificationReportsFail();
      })
      .finally(() => {
        dispatch(notificationReportSlice.actions.changeCurrentPage(page));
      });
  };

export const refreshNotificationReports =
  () => (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(
      getNotificationReports(
        getState().notificationReport.notificationReports.page
      )
    );
  };
