import { Button, Form, Input } from "antd";
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { useMount } from "react-use";
import styled from "styled-components";
import { getChannels } from "../../api/channelAPI";
import { getSLAs } from "../../api/slaAPI";
import { getUsers } from "../../api/userAPI";
import { Function } from "../../constants/Function";
import { Permission } from "../../constants/Permission";
import { usePermission } from "../../hooks/usePermissions";
import { slaSlice } from "../../store/slaSlice";
import { formatFuzzySearch } from "../../utils/formatFuzzySearch";
import { formatSearchStatus } from "../../utils/formatSearchStatus";

export const SLASearchCriteria: FunctionComponent = () => {
  const slaManagementPermission = usePermission(Function.SlaAlert);
  const [form] = Form.useForm<{ searchText?: string }>();
  const dispatch = useDispatch();
  const handleCreateSLA = () => {
    dispatch(slaSlice.actions.toggleCreateModal());
  };

  const fetch = () => {
    const values = form.getFieldsValue();
    const filter = formatFuzzySearch(["name"], values.searchText);
    dispatch(getSLAs(1, filter));
  };

  useMount(() => {
    fetch();
    const filter = formatSearchStatus(0);
    dispatch(getChannels(1, filter));
    dispatch(getUsers(1, filter));
  });

  return (
    <ActionWrapper>
      <Form layout={"inline"} form={form}>
        <Form.Item label="SLA 名稱" name="searchText">
          <Input
            allowClear
            placeholder={"請輸入 SLA 名稱"}
            style={{ width: 200 }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={fetch} htmlType="submit">
            搜尋
          </Button>
        </Form.Item>
      </Form>
      {slaManagementPermission.includes(Permission.Add) && (
        <Button type={"primary"} onClick={handleCreateSLA}>
          新增SLA
        </Button>
      )}
    </ActionWrapper>
  );
};

const ActionWrapper = styled.div`
  margin: 0 0 24px 0;
  display: flex;
  justify-content: space-between;
`;
