import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HeadOfficeState } from "../types/HeadOffice";

const initialState: HeadOfficeState = {
  list: [],
  emails: [],
};

export const headOfficeSlice = createSlice({
  name: "headOffice",
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<HeadOfficeState["list"]>) => {
      state.list = action.payload;
    },
    setEmailsList: (
      state,
      action: PayloadAction<HeadOfficeState["emails"]>
    ) => {
      state.emails = action.payload;
    },
  },
});
