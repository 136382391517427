import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TabState {
  currentTab: string;
}

const initialState: TabState = {
  currentTab: "",
};

export const tabSlice = createSlice({
  name: "tab",
  initialState,
  reducers: {
    changeTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload;
    },
  },
});

export const { changeTab } = tabSlice.actions;
