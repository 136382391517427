import { Form, Input, InputNumber, Modal, Radio, Select } from "antd";
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { useToggle } from "react-use";
import { refreshSLAs } from "../../api/slaAPI";
import { SLAType } from "../../constants/SLAType";
import { AppContext } from "../../core/api/AppContext";
import { useAppSelector } from "../../store/hooks";
import { slaSlice } from "../../store/slaSlice";
import { displaySLAType } from "../../utils/displaySLAType";

export const CreateSLAModal: FunctionComponent = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useToggle(false);
  const dispatch = useDispatch();
  const { createModalVisible } = useAppSelector((state) => state.sla.modal);
  const channels = useAppSelector((state) => state.channel.channels);

  const handleOk = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      AppContext.ApiExecutor.createSLA({
        ...values,
        filterUserIds: values.filterUserIds?.split(",") || [],
      })
        .then(() => {
          dispatch(slaSlice.actions.toggleCreateModal());
          dispatch(refreshSLAs());
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const handleCancel = () => {
    dispatch(slaSlice.actions.toggleCreateModal());
  };

  const handleClose = () => {
    form.resetFields();
  };

  return (
    <Modal
      title="新增 SLA"
      visible={createModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"儲存"}
      confirmLoading={loading}
      cancelText={"取消"}
      destroyOnClose={true}
      afterClose={handleClose}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
          status: 0,
          type: 0,
          intervalMinutes: 5,
          snoozeEnabled: true,
        }}
        form={form}
      >
        <Form.Item
          label="提醒名稱"
          name="name"
          rules={[{ required: true, message: "請輸入提醒名稱" }]}
        >
          <Input placeholder={"提醒名稱"} />
        </Form.Item>
        <Form.Item label="狀態" name="status" rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value={0}>啟用</Radio>
            <Radio value={1}>停用</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="SLA 類型" name="type" rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value={SLAType.Bot}>{displaySLAType(SLAType.Bot)}</Radio>
            <Radio value={SLAType.Jira}>{displaySLAType(SLAType.Jira)}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="過濾清單" name="filterUserIds">
          <Input placeholder={"skype1,skype2"} />
        </Form.Item>
        <Form.Item label="服務提醒交談清單" name="channelIds">
          <Select allowClear style={{ width: 200 }} mode={"multiple"}>
            {channels.list.map((channel) => (
              <Select.Option key={channel.id} value={channel.id}>
                {channel.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="提醒時間間隔"
          name="intervalMinutes"
          rules={[{ required: true }]}
        >
          <InputNumber addonAfter={"mins"} />
        </Form.Item>
        <Form.Item label="稍後提醒" name="snoozeEnabled">
          <Radio.Group>
            <Radio value={true}>啟用</Radio>
            <Radio value={false}>停用</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="發送至" name="sendToChannels">
          <Select allowClear style={{ width: 200 }} mode={"multiple"}>
            {channels.list.map((channel) => (
              <Select.Option key={channel.id} value={channel.id}>
                {channel.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
