import { Form, Input, Modal, Radio, Select, Tree } from "antd";
import _ from "lodash";
import React, { FunctionComponent, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useToggle } from "react-use";
import { refreshUsers } from "../../api/userAPI";
import { Permission } from "../../constants/Permission";
import { permissionTreeData } from "../../constants/permissionTreeData";
import { Role } from "../../constants/Role";
import { Status } from "../../constants/Status";
import { AppContext } from "../../core/api/AppContext";
import { useAppSelector } from "../../store/hooks";
import { userSlice } from "../../store/userSlice";
import { FunctionPermission } from "../../types/Auth";
import { UserFields } from "../../types/User";
import { convertKeysToPermissions } from "../../utils/convertKeysToPermissions";
import { convertPermissionsToKeys } from "../../utils/convertPermissionsToKeys";
import { allPermissionKeys } from "../../constants/allPermissionKeys";

export const CreateUserModal: FunctionComponent = () => {
  const [form] = Form.useForm<UserFields>();
  const [loading, setLoading] = useToggle(false);
  const dispatch = useDispatch();
  const [permissions, setPermissions] = useState<FunctionPermission[]>([]);
  const { createModalVisible } = useAppSelector((state) => state.user.modal);
  const { rolePermission } = useAppSelector((state) => state.auth);
  const [role, setRole] = useState<Role>(Role.User);
  const [keys, setKeys] = useState<string[]>([]);

  const treeData = useMemo(() => {
    const userPermissionKeys = convertPermissionsToKeys(
      rolePermission?.permissions
    );

    return permissionTreeData.map((func) => {
      return {
        ...func,
        children: func.children?.map((data) => {
          const permission = Number(String(data.key).split("-")[1]);
          const disableCheckbox =
            role === Role.User
              ? !(permission === Permission.View)
              : !userPermissionKeys.includes(String(data.key));
          if (disableCheckbox && keys.includes(String(data.key))) {
            setKeys((prevKeys) => {
              return prevKeys.filter((key) => key !== String(data.key));
            });
          }
          return { ...data, disableCheckbox };
        }),
      };
    });
  }, [role, rolePermission]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      const functionGroup = _.groupBy(permissions, "function");
      const sumPermissions = _.keys(functionGroup).map((functionKey) => {
        return {
          function: Number(functionKey),
          permission: _.sumBy(functionGroup[functionKey], "permission"),
        };
      });

      setLoading(true);
      AppContext.ApiExecutor.createUser({
        ...values,
        permissions: sumPermissions,
      })
        .then(() => {
          dispatch(userSlice.actions.toggleCreateModal());
          dispatch(refreshUsers());
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const handleCancel = () => {
    dispatch(userSlice.actions.toggleCreateModal());
  };

  const handleClose = () => {
    form.resetFields();
    setKeys([]);
  };

  const onCheck = (checkedKeys: string[]) => {
    setKeys(checkedKeys);
    const checkedPermissions = convertKeysToPermissions(checkedKeys);
    setPermissions(checkedPermissions);
  };

  const onSelectRole = (value: Role) => {
    setRole(value);
    if (value === Role.Admin) {
      setKeys(allPermissionKeys);
    } else {
      setKeys([]);
    }
  };

  return (
    <Modal
      title="新增使用者"
      visible={createModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"儲存"}
      confirmLoading={loading}
      cancelText={"取消"}
      destroyOnClose={true}
      afterClose={handleClose}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ status: Status.Active, role: Role.User }}
        form={form}
      >
        <Form.Item
          label="使用者帳號"
          name="idFromOkta"
          rules={[{ required: true, message: "請輸入使用者帳號" }]}
        >
          <Input placeholder={"使用者帳號"} />
        </Form.Item>
        <Form.Item label="角色" name="role" rules={[{ required: true }]}>
          <Select onChange={onSelectRole}>
            <Select.Option value={Role.Admin}>管理員</Select.Option>
            <Select.Option value={Role.User}>一般使用者</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="狀態" name="status" rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value={Status.Active}>啟用</Radio>
            <Radio value={Status.Inactive}>停用</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="權限" name="permissions">
          <Tree
            checkable
            checkedKeys={keys}
            onCheck={(keys) => onCheck(keys as string[])}
            treeData={treeData}
          />
        </Form.Item>
        <Form.Item label="備註" name="description">
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
