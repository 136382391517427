import { useMemo } from "react";
import { Permission } from "../constants/Permission";
import { Function } from "../constants/Function";
import { useAppSelector } from "../store/hooks";
import _ from "lodash";

export function usePermissions() {
  const { rolePermission } = useAppSelector((state) => state.auth);

  const permissionMap = useMemo(() => {
    return rolePermission?.permissions
      ? _.mapValues(
          _.keyBy(rolePermission?.permissions, "function"),
          "permission"
        )
      : {};
  }, [rolePermission]);

  return permissionMap;
}

export function usePermission(func: Function): Permission[] {
  const permissionMap = usePermissions();

  const permissionArray = useMemo(() => {
    return (
      permissionMap[func]
        ?.toString(2)
        .padStart(4, "0")
        .split("")
        .map((permission, index) => {
          return Math.pow(2, 3 - index) * Number(permission);
        })
        .filter((permission) => permission) || []
    );
  }, [permissionMap]);

  return permissionArray;
}
