import { Form, Input, Modal, Radio, Select, Spin } from "antd";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useToggle } from "react-use";
import { refreshGroups } from "../../api/groupAPI";
import { AppContext } from "../../core/api/AppContext";
import { groupSlice } from "../../store/groupSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { EditGroupPayload } from "../../types/Group";
import { getChannelByID } from "../../utils/getChannelByID";
import { useChannels } from "../../hooks/useChannels";

export const EditGroupModal: FunctionComponent = () => {
  const [form] = Form.useForm<EditGroupPayload>();
  const [loading, setLoading] = useToggle(false);
  const [initialValues, setInitialValues] = useState<EditGroupPayload>();
  const dispatch = useAppDispatch();
  const editingGroup = useAppSelector((state) => state.group.editingGroup);
  const { editModalVisible } = useAppSelector((state) => state.group.modal);
  const {
    channels,
    loading: loadingChannels,
    loadingRef: loadingRefChannel,
    hasNextPage: hasNextChannelPage,
    getChannelsByName,
    refetchAll,
  } = useChannels(true);

  const [headOffices, setHeadOffices] = useState("");

  const handleOk = () => {
    form.validateFields().then((values) => {
      setLoading(true);

      const payload = {
        ...values,
        channels: values.channels,
      };

      AppContext.ApiExecutor.updateGroup(editingGroup!.id, payload)
        .then(() => {
          refetchAll();
          dispatch(groupSlice.actions.toggleEditModal());
          dispatch(refreshGroups());
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const handleCancel = () => {
    dispatch(groupSlice.actions.toggleEditModal());
  };

  const handleClose = () => {
    dispatch(groupSlice.actions.setEditingGroup(null));
  };

  const onSelectChannel = (selectedChannels: string[]): void => {
    const headOffices = selectedChannels
      .map((channelID) => getChannelByID(channelID, channels)?.headOffice)
      .filter((headOffice) => !!headOffice)
      .join(", ");

    setHeadOffices(headOffices);
  };

  useEffect(() => {
    if (editingGroup && editModalVisible) {
      onSelectChannel(editingGroup.channels);
    }
  }, [editModalVisible]);

  useEffect(() => {
    setInitialValues(editingGroup || undefined);
  }, [editingGroup]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues]);

  const onSearchChannel = (name: string) => {
    getChannelsByName(name);
  };

  return (
    <Modal
      title="編輯分群"
      visible={editModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"儲存"}
      cancelText={"取消"}
      destroyOnClose={true}
      afterClose={handleClose}
      confirmLoading={loading}
    >
      {editModalVisible && (
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={initialValues}
          form={form}
        >
          <Form.Item
            label="分群名稱"
            name="name"
            rules={[{ required: true, message: "請輸入分群名稱" }]}
          >
            <Input placeholder={"分群名稱"} />
          </Form.Item>
          <Form.Item label="交談清單" name="channels">
            <Select
              mode={"multiple"}
              onChange={onSelectChannel}
              onSearch={onSearchChannel}
              filterOption={false}
              notFoundContent={loadingChannels ? <Spin size="small" /> : null}
            >
              {channels.map((channel) => (
                <Select.Option key={channel.id} value={channel.id}>
                  {channel.name}
                </Select.Option>
              ))}
              {(loadingChannels || hasNextChannelPage) && (
                <Select.Option value={"ref"}>
                  <div ref={loadingRefChannel}>
                    <Spin />
                  </div>
                </Select.Option>
              )}
            </Select>
          </Form.Item>
          <Form.Item label="總社">
            <Input value={headOffices} disabled />
          </Form.Item>
          <Form.Item label="說明" name="description">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item label="狀態" name="status" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={0}>啟用</Radio>
              <Radio value={1}>停用</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
