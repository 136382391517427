import { Form, Input, Modal, Radio, Select } from "antd";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useToggle } from "react-use";
import { refreshChannels } from "../../api/channelAPI";
import { AppContext } from "../../core/api/AppContext";
import { channelSlice } from "../../store/channelSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { EditChannelFields } from "../../types/Channel";

export const EditChannelModal: FunctionComponent = () => {
  const [form] = Form.useForm<EditChannelFields>();
  const [loading, setLoading] = useToggle(false);
  const [initialValues, setInitialValues] = useState<EditChannelFields>();
  const dispatch = useAppDispatch();
  const editingChannel = useAppSelector(
    (state) => state.channel.editingChannel
  );

  const { editModalVisible } = useAppSelector((state) => state.channel.modal);

  const handleOk = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      AppContext.ApiExecutor.updateChannel(editingChannel!.id, values)
        .then(() => {
          dispatch(channelSlice.actions.toggleEditModal());
          dispatch(refreshChannels());
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const handleCancel = () => {
    dispatch(channelSlice.actions.toggleEditModal());
  };

  const handleClose = () => {
    dispatch(channelSlice.actions.setEditingChannel(null));
  };

  useEffect(() => {
    setInitialValues(editingChannel || undefined);
  }, [editingChannel]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues]);

  return (
    <Modal
      title="編輯交談"
      visible={editModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"儲存"}
      cancelText={"取消"}
      destroyOnClose={true}
      afterClose={handleClose}
      confirmLoading={loading}
    >
      {editModalVisible && (
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={initialValues}
          form={form}
        >
          <Form.Item
            label="交談名稱"
            name="name"
            rules={[{ required: true, message: "請輸入交談名稱" }]}
          >
            <Input placeholder={"交談名稱"} />
          </Form.Item>
          <Form.Item
            label="總社"
            name="headOffice"
            rules={[{ required: true, message: "請輸入總社" }]}
          >
            <Input placeholder={"總社"} />
          </Form.Item>
          <Form.Item
            label="總社層級"
            name="tier"
            rules={[{ required: true, message: "請選擇總社層級" }]}
          >
            <Select>
              <Select.Option value={1}>1</Select.Option>
              <Select.Option value={2}>2</Select.Option>
              <Select.Option value={3}>3</Select.Option>
              <Select.Option value={4}>4</Select.Option>
              <Select.Option value={5}>5</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="MA"
            name="masterAgents"
            rules={[{ required: false, message: "請輸入MA" }]}
          >
            <Input placeholder={"Master Agent"} />
          </Form.Item>
          <Form.Item label="狀態" name="status" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={0}>啟用</Radio>
              <Radio value={1}>停用</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="來源"
            name="botType"
            rules={[{ required: true, message: "請選擇來源" }]}
          >
            <Select disabled>
              <Select.Option value={0}>Skype</Select.Option>
              <Select.Option value={1}>Telegram</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="說明"
            name="description"
            rules={[{ required: true, message: "請輸入內容" }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
