import { Form, Input, Modal, Radio, Select, Tree } from "antd";
import _ from "lodash";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useToggle } from "react-use";
import { refreshUsers } from "../../api/userAPI";
import { Permission } from "../../constants/Permission";
import { permissionTreeData } from "../../constants/permissionTreeData";
import { Role } from "../../constants/Role";
import { Status } from "../../constants/Status";
import { AppContext } from "../../core/api/AppContext";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { userSlice } from "../../store/userSlice";
import { FunctionPermission } from "../../types/Auth";
import { UserFields } from "../../types/User";
import { convertKeysToPermissions } from "../../utils/convertKeysToPermissions";
import { convertPermissionsToKeys } from "../../utils/convertPermissionsToKeys";
import { allPermissionKeys } from "../../constants/allPermissionKeys";

export const EditUserModal: FunctionComponent = () => {
  const [form] = Form.useForm<UserFields>();
  const [loading, setLoading] = useToggle(false);
  const [initialValues, setInitialValues] = useState<UserFields>();
  const dispatch = useAppDispatch();
  const [keys, setKeys] = useState<string[]>([]);
  const editingUser = useAppSelector((state) => state.user.editingUser);
  const { rolePermission } = useAppSelector((state) => state.auth);
  const [permissions, setPermissions] = useState<FunctionPermission[]>([]);
  const { editModalVisible } = useAppSelector((state) => state.user.modal);
  const [role, setRole] = useState<Role>(Role.User);

  const treeData = useMemo(() => {
    const userPermissionKeys = convertPermissionsToKeys(
      rolePermission?.permissions
    );

    return permissionTreeData.map((func) => {
      return {
        ...func,
        children: func.children?.map((data) => {
          const permission = Number(String(data.key).split("-")[1]);
          const disableCheckbox =
            role === Role.User
              ? !(permission === Permission.View)
              : !userPermissionKeys.includes(String(data.key));
          if (disableCheckbox && keys.includes(String(data.key))) {
            setKeys((prevKeys) => {
              return prevKeys.filter((key) => key !== String(data.key));
            });
          }
          return { ...data, disableCheckbox };
        }),
      };
    });
  }, [role, rolePermission]);

  const handleOk = () => {
    form.validateFields().then((values) => {
      const functionGroup = _.groupBy(permissions, "function");
      const sumPermissions = _.keys(functionGroup).map((functionKey) => {
        return {
          function: Number(functionKey),
          permission: _.sumBy(functionGroup[functionKey], "permission"),
        };
      });
      setLoading(true);
      AppContext.ApiExecutor.updateUser(editingUser!.id, {
        ...values,
        permissions: sumPermissions,
      })
        .then(() => {
          dispatch(userSlice.actions.toggleEditModal());
          dispatch(refreshUsers());
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const handleCancel = () => {
    dispatch(userSlice.actions.toggleEditModal());
  };

  const handleClose = () => {
    dispatch(userSlice.actions.setEditingUser(null));
  };

  useEffect(() => {
    setInitialValues(editingUser || undefined);
  }, [editingUser]);

  useEffect(() => {
    if (initialValues) {
      setRole(initialValues.role);
      form.setFieldsValue(initialValues);
      const initialPermissions = convertPermissionsToKeys(
        initialValues.permissions
      );
      setKeys(initialPermissions);
      setPermissions(convertKeysToPermissions(initialPermissions));
    } else {
      form.resetFields();
    }
  }, [initialValues]);

  const onCheck = (checkedKeys: string[]) => {
    setKeys(checkedKeys);
    const checkedPermissions = convertKeysToPermissions(checkedKeys);
    setPermissions(checkedPermissions);
  };

  const onSelectRole = (value: Role) => {
    setRole(value);
    if (value === Role.Admin) {
      setKeys(allPermissionKeys);
      setPermissions(convertKeysToPermissions(allPermissionKeys));
    }
  };

  return (
    <Modal
      title="編輯使用者"
      visible={editModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"儲存"}
      cancelText={"取消"}
      destroyOnClose={true}
      afterClose={handleClose}
      confirmLoading={loading}
    >
      {editModalVisible && (
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={initialValues}
          form={form}
        >
          <Form.Item
            label="使用者帳號"
            name="idFromOkta"
            rules={[{ required: true, message: "請輸入使用者帳號" }]}
          >
            <Input placeholder={"使用者帳號"} />
          </Form.Item>
          <Form.Item label="角色" name="role" rules={[{ required: true }]}>
            <Select onChange={onSelectRole}>
              <Select.Option value={Role.Admin}>管理員</Select.Option>
              <Select.Option value={Role.User}>一般使用者</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="狀態" name="status" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={Status.Active}>啟用</Radio>
              <Radio value={Status.Inactive}>停用</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="權限" name="permissions">
            <Tree
              checkable
              checkedKeys={keys}
              onCheck={(keys) => onCheck(keys as string[])}
              treeData={treeData}
            />
          </Form.Item>
          <Form.Item label="備註" name="description">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
