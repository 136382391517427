import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { ChannelSearchCriteria } from "./ChannelSearchCriteria";
import { ChannelTable } from "./ChannelTable";
import { DisplayChannelModal } from "./DisplayChannelModal";
import { EditChannelModal } from "./EditChannelModal";

export const ChannelManagement: FunctionComponent = () => {
  return (
    <Wrapper>
      <ChannelSearchCriteria />
      <ChannelTable />
      <EditChannelModal />
      <DisplayChannelModal />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
