import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { DisplayNotificationReportModal } from "./DisplayNotificationReportModal";
import { NotificationReportSearchCriteria } from "./NotificationReportSearchCriteria";
import { NotificationReportTable } from "./NotificationReportTable";
import { DisplayNotificationModal } from "../notification-management/DisplayNotificationModal";

export const NotificationReport: FunctionComponent = () => {
  return (
    <Wrapper>
      <NotificationReportSearchCriteria />
      <NotificationReportTable />
      <DisplayNotificationReportModal />
      <DisplayNotificationModal />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
