import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchParams } from "../types/api";
import {
  GetNotificationReportsResponse,
  NotificationReport,
  NotificationReportState,
} from "../types/NotificationReport";

const initialState: NotificationReportState = {
  modal: {
    createModalVisible: false,
    editModalVisible: false,
    displayModalVisible: false,
  },
  notificationReports: {
    list: [],
    fetching: false,
    total: 0,
    totalPages: 0,
    page: 0,
  },
  displayNotificationReport: null,
  searchParams: {
    page: 1,
    filter: "",
    orderBy: "",
  },
};

export const notificationReportSlice = createSlice({
  name: "notificationReport",
  initialState,
  reducers: {
    fetchingNotificationReports: (state) => {
      state.notificationReports.fetching = true;
    },
    fetchNotificationReportsSuccess: (
      state,
      action: PayloadAction<GetNotificationReportsResponse>
    ) => {
      state.notificationReports.fetching = false;
      state.notificationReports.list = action.payload.notificationReports;
      state.notificationReports.total = action.payload.count;
      state.notificationReports.totalPages = action.payload.totalPages;
    },
    fetchNotificationReportsFail: (state) => {
      state.notificationReports.fetching = false;
      state.notificationReports.list = [];
    },
    setDisplayNotificationReport: (
      state,
      action: PayloadAction<Nullable<NotificationReport>>
    ) => {
      state.displayNotificationReport = action.payload;
    },
    toggleDisplayModal: (state) => {
      state.modal.displayModalVisible = !state.modal.displayModalVisible;
    },
    changeCurrentPage: (state, action: PayloadAction<number>) => {
      state.notificationReports.page = action.payload;
    },
    setSearchParams: (state, action: PayloadAction<SearchParams>) => {
      state.searchParams = action.payload;
    },
  },
});
