import { Dispatch } from "react";
import { AppContext } from "../core/api/AppContext";
import { channelSlice } from "../store/channelSlice";
import { messageTemplateSlice } from "../store/messageTemplateSlice";
import { AppDispatch, RootState } from "../store/store";

export const getChannels =
  (page: number, filter?: string, orderBy?: string) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const searchFilter =
      filter !== undefined ? filter : getState().channel.searchParams.filter;

    const searchOrderBy =
      orderBy !== undefined ? orderBy : getState().channel.searchParams.orderBy;

    dispatch(
      messageTemplateSlice.actions.setSearchParams({
        page,
        filter: searchFilter,
        orderBy: searchOrderBy,
      })
    );

    dispatch(channelSlice.actions.fetchingChannels());

    AppContext.ApiExecutor.getChannels({
      page,
      filter: searchFilter,
      orderBy: searchOrderBy,
    })
      .then(({ data }) => {
        dispatch(channelSlice.actions.fetchChannelsSuccess(data));
      })
      .catch(() => {
        channelSlice.actions.fetchChannelsFail();
      })
      .finally(() => {
        dispatch(channelSlice.actions.changeCurrentPage(page));
      });
  };

export const refreshChannels =
  () => (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(getChannels(getState().channel.channels.page));
  };
