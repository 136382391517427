import { Dispatch } from "react";
import { AppContext } from "../core/api/AppContext";
import { slaSlice } from "../store/slaSlice";
import { AppDispatch, RootState } from "../store/store";

export const getSLAs =
  (page: number, filter?: string, orderBy?: string) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const searchFilter =
      filter !== undefined ? filter : getState().sla.searchParams.filter;

    const searchOrderBy =
      orderBy !== undefined ? orderBy : getState().sla.searchParams.orderBy;

    dispatch(
      slaSlice.actions.setSearchParams({
        page,
        filter: searchFilter,
        orderBy: searchOrderBy,
      })
    );

    dispatch(slaSlice.actions.fetchingSLAs());

    AppContext.ApiExecutor.getSLAs({
      page,
      filter: searchFilter,
      orderBy: searchOrderBy,
    })
      .then(({ data }) => {
        dispatch(slaSlice.actions.fetchSLAsSuccess(data));
      })
      .catch(() => {
        slaSlice.actions.fetchSLAsFail();
      })
      .finally(() => {
        dispatch(slaSlice.actions.changeCurrentPage(page));
      });
  };

export const refreshSLAs =
  () => (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(getSLAs(getState().sla.slas.page));
  };
