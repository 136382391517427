import { EditOutlined } from "@ant-design/icons";
import { Table } from "antd";
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { getSLAs } from "../../api/slaAPI";
import { Function } from "../../constants/Function";
import { pageSize } from "../../constants/pagination";
import { Permission } from "../../constants/Permission";
import { usePermission } from "../../hooks/usePermissions";
import { useAppSelector } from "../../store/hooks";
import { slaSlice } from "../../store/slaSlice";
import { SLA } from "../../types/SLA";
import { displaySLAType } from "../../utils/displaySLAType";
import { displayStatus } from "../../utils/displayStatus";
import { formatTime } from "../../utils/formatTime";
import { getOrderBy } from "../../utils/getOrderBy";
import { showTotalData } from "../../utils/showTotalData";
import { ResourceName } from "../common/ResourceName";
import { TableActionWrapper } from "../common/TableActionWrapper";

export const SLATable: FunctionComponent = () => {
  const slaManagementPermission = usePermission(Function.SlaAlert);
  const dispatch = useDispatch();
  const { slas, searchParams } = useAppSelector((state) => state.sla);

  const handleEdit = (sla: SLA): void => {
    dispatch(
      slaSlice.actions.setEditingSLA({
        ...sla,
        channelIds: sla.slaChannels?.map((s) => s.channelId),
        sendToChannels: (sla as any).sendToChannels.split(","),
      })
    );
    dispatch(slaSlice.actions.toggleEditModal());
  };

  const handleDisplay = (sla: SLA): void => {
    dispatch(slaSlice.actions.setDisplaySLA(sla));
    dispatch(slaSlice.actions.toggleDisplayModal());
  };

  const onFilterChange = (
    pagination: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<SLA> | SorterResult<SLA>[]
  ) => {
    const orderBy = getOrderBy<SLA>(sorter);
    dispatch(getSLAs(pagination.current ?? 1, searchParams.filter, orderBy));
  };

  return (
    <Table<SLA>
      tableLayout={"auto"}
      loading={slas.fetching}
      dataSource={slas.list}
      scroll={{ y: 800, x: 700 }}
      onChange={onFilterChange}
      pagination={{
        pageSize,
        showSizeChanger: false,
        total: slas.total,
        current: slas.page,
        showTotal: () => showTotalData(slas.total),
      }}
      rowKey={"id"}
    >
      <Table.Column<SLA>
        sorter={true}
        title="SLA 名稱"
        dataIndex="name"
        width={150}
        render={(name: string, data) => (
          <ResourceName onClick={() => handleDisplay(data)}>
            {name}
          </ResourceName>
        )}
      />
      <Table.Column
        sorter={true}
        title="SLA 類型"
        dataIndex="type"
        render={(type) => displaySLAType(type)}
      />
      <Table.Column
        sorter={true}
        title="更新人員"
        dataIndex="updatedUsername"
        width={150}
      />
      <Table.Column
        sorter={true}
        title="更新時間"
        dataIndex="updatedAt"
        width={200}
        align={"center"}
        render={(e: string) => formatTime(e)}
      />
      <Table.Column<SLA>
        sorter={true}
        title="狀態"
        dataIndex="status"
        align={"center"}
        width={100}
        render={(status: number) => displayStatus(status)}
      />
      <Table.Column<SLA>
        title="操作"
        dataIndex="id"
        align={"center"}
        width={100}
        render={(id, data) => {
          return (
            <TableActionWrapper>
              {slaManagementPermission.includes(Permission.Edit) && (
                <EditOutlined onClick={() => handleEdit(data)} />
              )}
            </TableActionWrapper>
          );
        }}
      />
    </Table>
  );
};
