import { Function } from "../constants/Function";
import _ from "lodash";

export const getFunctionSideKey = (
  func: Function = Function.UserManagement
): string => {
  const functionSideItemMap: Record<Function, string> = {
    [Function.UserManagement]: "user",
    [Function.MessageTemplate]: "template",
    [Function.Channel]: "channel",
    [Function.Group]: "group",
    [Function.Notification]: "notification",
    [Function.SlaAlert]: "sla",
    [Function.Jira]: "",
    [Function.Report]: "notificationReport",
  };

  return _.get(functionSideItemMap, func, "");
};
