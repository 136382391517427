import { SendOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Input, Modal, notification as uiNotification, Table } from "antd";
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import React, { FunctionComponent, useState } from "react";
import { useDispatch } from "react-redux";
import { useToggle } from "react-use";
import {
  getNotificationReports,
  refreshNotificationReports,
} from "../../api/notificationReportAPI";
import { pageSize } from "../../constants/pagination";
import { AppContext } from "../../core/api/AppContext";
import { useAppSelector } from "../../store/hooks";
import { notificationReportSlice } from "../../store/notificationReportSlice";
import { NotificationReport } from "../../types/NotificationReport";
import { displaySendStatus } from "../../utils/displaySendStatus";
import { formatTime } from "../../utils/formatTime";
import { getOrderBy } from "../../utils/getOrderBy";
import { showTotalData } from "../../utils/showTotalData";
import { ResourceName } from "../common/ResourceName";
import { TableActionWrapper } from "../common/TableActionWrapper";
import { notificationSlice } from "../../store/notificationSlice";

export const NotificationReportTable: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [displaySend, setDisplaySend] = useToggle(false);
  const [sendInput, setSendInput] = useState("");
  const { notificationReports, searchParams } = useAppSelector(
    (state) => state.notificationReport
  );
  const [willSendNotify, setWillSendNotify] = useState<NotificationReport>();

  const toggleSend = (notificationReport: NotificationReport): void => {
    setWillSendNotify(notificationReport);
    setDisplaySend(true);
  };

  const toggleDisplay = (notificationReport: NotificationReport): void => {
    AppContext.ApiExecutor.getNotifications({
      filter: `id=${notificationReport.notificationId}`,
    }).then(({ data }) => {
      if (data.notifications[0]) {
        dispatch(
          notificationSlice.actions.setDisplayNotification(
            data.notifications[0]
          )
        );
        dispatch(notificationSlice.actions.toggleDisplayModal());
      }
    });
  };

  const handleSend = (): void => {
    if (sendInput !== "Yes") {
      uiNotification.warn({ message: "確認送出請輸入 Yes", placement: "top" });
      return;
    }
    if (willSendNotify) {
      AppContext.ApiExecutor.resendNotificationReport(willSendNotify.id)
        .then(() => {
          dispatch(refreshNotificationReports());
          uiNotification.success({
            message: "寄送成功",
            placement: "bottomRight",
          });
        })
        .catch(() => {
          uiNotification.warning({
            message: "寄送失敗",
            placement: "bottomRight",
          });
        })
        .finally(() => {
          setDisplaySend(false);
        });
    }
  };

  const handleDisplay = (notificationReport: NotificationReport): void => {
    dispatch(
      notificationReportSlice.actions.setDisplayNotificationReport(
        notificationReport
      )
    );
    dispatch(notificationReportSlice.actions.toggleDisplayModal());
  };

  const onFilterChange = (
    config: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<NotificationReport>
      | SorterResult<NotificationReport>[]
  ) => {
    const orderBy = getOrderBy<NotificationReport>(sorter);
    dispatch(
      getNotificationReports(config.current ?? 1, searchParams.filter, orderBy)
    );
  };

  return (
    <>
      <Table<NotificationReport>
        tableLayout={"auto"}
        loading={notificationReports.fetching}
        dataSource={notificationReports.list}
        scroll={{ y: 800, x: 700 }}
        onChange={onFilterChange}
        pagination={{
          pageSize,
          showSizeChanger: false,
          total: notificationReports.total,
          current: notificationReports.page,
          showTotal: () => showTotalData(notificationReports.total),
        }}
        rowKey={"id"}
      >
        <Table.Column<NotificationReport>
          title="通知名稱"
          dataIndex="notificationName"
          sorter={true}
          width={150}
          render={(name: string, data) => (
            <ResourceName onClick={() => handleDisplay(data)}>
              {name}
            </ResourceName>
          )}
        />
        <Table.Column<NotificationReport>
          title="發送編號"
          dataIndex="id"
          sorter={true}
          width={150}
        />
        <Table.Column
          sorter={true}
          title="發送⼈員"
          dataIndex="userName"
          width={150}
        />
        <Table.Column
          sorter={true}
          title="發送時間"
          dataIndex="sentAt"
          width={200}
          align={"center"}
          render={(e: string) => formatTime(e)}
        />
        <Table.Column
          sorter={true}
          title="發送結果"
          dataIndex="result"
          align={"center"}
          width={100}
          render={(result) => displaySendStatus(result)}
        />
        <Table.Column<NotificationReport>
          title="操作"
          dataIndex="id"
          align={"center"}
          width={100}
          render={(id, data) => {
            return (
              <TableActionWrapper>
                {data.notificationName !== "" && (
                  <SendOutlined onClick={() => toggleSend(data)} />
                )}
                <InfoCircleOutlined onClick={() => toggleDisplay(data)} />
              </TableActionWrapper>
            );
          }}
        />
      </Table>
      <Modal
        visible={displaySend}
        title="確認發送通知？"
        onCancel={() => {
          setDisplaySend(false);
        }}
        destroyOnClose={true}
        onOk={handleSend}
        afterClose={() => {
          setSendInput("");
          setWillSendNotify(undefined);
        }}
      >
        <div style={{ marginBottom: 10 }}>
          {`確認發送通知「${willSendNotify?.notificationName}」，請輸入 Yes`}
        </div>

        <div style={{ marginBottom: 10 }}>
          發送目標：
          {willSendNotify?.failures
            .map((resource) => resource.channelName)
            .join(", ")}
        </div>
        <Input
          placeholder={"Yes"}
          value={sendInput}
          onChange={(e) => setSendInput(e.target.value)}
        />
      </Modal>
    </>
  );
};
