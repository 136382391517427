import _ from "lodash";
import { NotificationType } from "../constants/NotificationType";

export const displayNotificationType = (
  notificationType: NotificationType = NotificationType.Chat
): string => {
  const typeMap: Record<NotificationType, string> = {
    [NotificationType.Chat]: "交談",
    [NotificationType.Email]: "Email",
  };

  return _.get(typeMap, notificationType, "");
};
