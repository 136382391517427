import { Dispatch } from "react";
import { AppContext } from "../core/api/AppContext";
import { groupSlice } from "../store/groupSlice";
import { AppDispatch, RootState } from "../store/store";

export const getGroups =
  (page: number, filter?: string, orderBy?: string) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const searchFilter =
      filter !== undefined ? filter : getState().group.searchParams.filter;
    const searchOrderBy =
      orderBy !== undefined ? orderBy : getState().group.searchParams.orderBy;

    dispatch(
      groupSlice.actions.setSearchParams({
        page,
        filter: searchFilter,
        orderBy: searchOrderBy,
      })
    );
    dispatch(groupSlice.actions.fetchingGroups());

    AppContext.ApiExecutor.getGroups({
      page,
      filter: searchFilter,
      orderBy: searchOrderBy,
    })
      .then(({ data }) => {
        dispatch(groupSlice.actions.fetchGroupsSuccess(data));
      })
      .catch(() => {
        groupSlice.actions.fetchGroupsFail();
      })
      .finally(() => {
        dispatch(groupSlice.actions.changeCurrentPage(page));
      });
  };

export const refreshGroups =
  () => (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(getGroups(getState().group.groups.page));
  };
