import { Dispatch } from "react";
import { AppContext } from "../core/api/AppContext";
import { notificationSlice } from "../store/notificationSlice";
import { AppDispatch, RootState } from "../store/store";

export const getNotifications =
  (page: number, filter?: string, orderBy?: string) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const searchFilter =
      filter !== undefined
        ? filter
        : getState().notification.searchParams.filter;
    const searchOrderBy =
      orderBy !== undefined
        ? orderBy
        : getState().notification.searchParams.orderBy;

    dispatch(
      notificationSlice.actions.setSearchParams({
        page,
        filter: searchFilter,
        orderBy: searchOrderBy,
      })
    );

    dispatch(notificationSlice.actions.fetchingNotifications());

    AppContext.ApiExecutor.getNotifications({
      page,
      filter: searchFilter,
      orderBy: searchOrderBy,
    })
      .then(({ data }) => {
        dispatch(notificationSlice.actions.fetchNotificationsSuccess(data));
      })
      .catch(() => {
        notificationSlice.actions.fetchNotificationsFail();
      })
      .finally(() => {
        dispatch(notificationSlice.actions.changeCurrentPage(page));
      });
  };

export const refreshNotifications =
  () => (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(getNotifications(getState().notification.notifications.page));
  };
