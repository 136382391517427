import { Modal } from "antd";
import React, { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { notificationSlice } from "../../store/notificationSlice";
import { displayStatus } from "../../utils/displayStatus";
import { Label, Value, Wrapper } from "../common/displayElement";
import { NotificationType } from "../../constants/NotificationType";
import { displayNotificationType } from "../../utils/displayNotificationType";

export const DisplayNotificationModal: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const emails = useAppSelector((state) => state.email.emails);
  const displayNotification = useAppSelector(
    (state) => state.notification.displayNotification
  );

  const { displayModalVisible } = useAppSelector(
    (state) => state.notification.modal
  );

  const handleOk = () => {
    dispatch(notificationSlice.actions.toggleDisplayModal());
  };

  const handleClose = () => {
    dispatch(notificationSlice.actions.setDisplayNotification(null));
  };

  return (
    <Modal
      title="通知內容"
      visible={displayModalVisible}
      onOk={handleOk}
      onCancel={handleOk}
      cancelButtonProps={{ style: { display: "none" } }}
      okText={"返回"}
      destroyOnClose={true}
      afterClose={handleClose}
    >
      <Wrapper>
        <Label>通知名稱：</Label>
        <Value>{displayNotification?.name}</Value>
      </Wrapper>
      <Wrapper>
        <Label>說明：</Label>
        <Value>{displayNotification?.description}</Value>
      </Wrapper>
      <Wrapper>
        <Label>狀態：</Label>
        <Value>{displayStatus(displayNotification?.status)}</Value>
      </Wrapper>
      <Wrapper>
        <Label>通知訊息：</Label>
        <Value>{displayNotification?.content}</Value>
      </Wrapper>
      <Wrapper>
        <Label>類型：</Label>
        <Value>{displayNotificationType(displayNotification?.type)}</Value>
      </Wrapper>
      {displayNotification?.type === NotificationType.Chat && (
        <Wrapper>
          <Label>交談清單：</Label>
          <Value>
            {displayNotification?.sendToChannels
              .map((channel) => channel.name)
              .join(", ")}
          </Value>
        </Wrapper>
      )}
      {displayNotification?.type === NotificationType.Email && (
        <Wrapper>
          <Label>總社：</Label>
          <Value>{displayNotification?.emailHeadOffice}</Value>
        </Wrapper>
      )}
    </Modal>
  );
};
