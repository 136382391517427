export enum Function {
  UserManagement = 0,
  MessageTemplate = 1,
  Channel = 2,
  Group = 3,
  Notification = 4,
  SlaAlert = 5,
  Jira = 6,
  Report = 7,
  Email = 8,
}
