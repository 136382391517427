import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { EmailSearchCriteria } from "./EmailSearchCriteria";
import { EmailTable } from "./EmailTable";
import { CreateEmailModal } from "./CreateEmailModal";
import { EditEmailModal } from "./EditEmailModal";
import { DisplayEmailModal } from "./DisplayEmailModal";

export const EmailManagement: FunctionComponent = () => {
  return (
    <Wrapper>
      <EmailSearchCriteria />
      <EmailTable />
      <CreateEmailModal />
      <EditEmailModal />
      <DisplayEmailModal />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
