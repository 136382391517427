import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { CreateTemplateModal } from "./CreateTemplateModal";
import { DisplayTemplateModal } from "./DisplayTemplateModal";
import { EditTemplateModal } from "./EditTemplateModal";
import { TemplateSearchCriteria } from "./TemplateSearchCriteria";
import { TemplateTable } from "./TemplateTable";

export const TemplateManagement: FunctionComponent = () => {
  return (
    <Wrapper>
      <TemplateSearchCriteria />
      <TemplateTable />
      <CreateTemplateModal />
      <EditTemplateModal />
      <DisplayTemplateModal />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
