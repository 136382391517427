import { SorterResult } from "antd/lib/table/interface";

export function getOrderBy<T>(
  sorter: SorterResult<T> | SorterResult<T>[]
): string | undefined {
  if (Array.isArray(sorter)) {
    return undefined;
  }

  const order = sorter?.order ? sorter.order.replace("end", "") : undefined;
  const field = sorter?.field;
  return order ? `${field} ${order}` : undefined;
}
