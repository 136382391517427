import { Layout, Menu } from "antd";
import React, { FunctionComponent } from "react";
import { Function } from "../../constants/Function";
import { usePermissions } from "../../hooks/usePermissions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { changeTab } from "../../store/tabSlice";

const { Sider } = Layout;

export const AppSider: FunctionComponent = () => {
  const permissions = usePermissions();
  const { currentTab } = useAppSelector((state) => state.tab);
  const dispatch = useAppDispatch();

  const handleTabClick = (e: { key: string }) => {
    dispatch(changeTab(e.key));
  };

  return (
    <Sider width={200} className="site-layout-background">
      <Menu
        mode="inline"
        onClick={handleTabClick}
        selectedKeys={[currentTab]}
        style={{ height: "100%", borderRight: 0 }}
      >
        {permissions[Function.UserManagement] > 0 && (
          <Menu.Item key="user">使用者管理</Menu.Item>
        )}
        {permissions[Function.Email] > 0 && (
          <Menu.Item key="email">Email 管理</Menu.Item>
        )}
        {permissions[Function.MessageTemplate] > 0 && (
          <Menu.Item key="template">範本管理</Menu.Item>
        )}
        {permissions[Function.Channel] > 0 && (
          <Menu.Item key="channel">交談管理</Menu.Item>
        )}
        {permissions[Function.Group] > 0 && (
          <Menu.Item key="group">分群管理</Menu.Item>
        )}
        {permissions[Function.Notification] > 0 && (
          <Menu.Item key="notification">通知管理</Menu.Item>
        )}
        {permissions[Function.SlaAlert] > 0 && (
          <Menu.Item key="sla">SLA 到期提醒</Menu.Item>
        )}
        {permissions[Function.Report] > 0 && (
          <Menu.SubMenu title={"報表"}>
            <Menu.Item key="notificationReport">發送通知報告</Menu.Item>
            <Menu.Item key="slaReport">SLA到期提醒報告</Menu.Item>
            <Menu.Item key="jiraReport">Jira開⽴⽇誌</Menu.Item>
          </Menu.SubMenu>
        )}
      </Menu>
    </Sider>
  );
};
