import "antd/dist/antd.css";
import Cookies from "js-cookie";
import React, { FunctionComponent, useState } from "react";
import { Provider } from "react-redux";
import { useMount, useSearchParam } from "react-use";
import { App } from "../components/App";
import { store } from "../store/store";
import { MetaInfo } from "../components/MetaInfo";

const AppPage: FunctionComponent = () => {
  const accessToken = useSearchParam("access_token");
  const [authGuard, setAuthGuard] = useState(false);

  useMount(() => {
    if (accessToken) {
      Cookies.set("userToken", accessToken);
    }
    setAuthGuard(true);
  });

  return (
    <Provider store={store}>
      <MetaInfo />
      {authGuard && <App />}
    </Provider>
  );
};

export default AppPage;
