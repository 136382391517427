import { Form, Input, Modal, Radio } from "antd";
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { useToggle } from "react-use";
import { refreshMessageTemplates } from "../../api/messageTemplateAPI";
import { AppContext } from "../../core/api/AppContext";
import { useAppSelector } from "../../store/hooks";
import { messageTemplateSlice } from "../../store/messageTemplateSlice";
import { MessageTemplateFields } from "../../types/MessageTemplate";

export const CreateTemplateModal: FunctionComponent = () => {
  const [form] = Form.useForm<MessageTemplateFields>();
  const [loading, setLoading] = useToggle(false);
  const dispatch = useDispatch();
  const { createModalVisible } = useAppSelector(
    (state) => state.messageTemplate.modal
  );

  const handleOk = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      AppContext.ApiExecutor.createMessageTemplate(values)
        .then(() => {
          dispatch(messageTemplateSlice.actions.toggleCreateModal());
          dispatch(refreshMessageTemplates());
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const handleCancel = () => {
    dispatch(messageTemplateSlice.actions.toggleCreateModal());
  };

  const handleClose = () => {
    form.resetFields();
  };

  return (
    <Modal
      title="新增範本"
      visible={createModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"儲存"}
      confirmLoading={loading}
      cancelText={"取消"}
      destroyOnClose={true}
      afterClose={handleClose}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ status: 0 }}
        form={form}
      >
        <Form.Item label="狀態" name="status" rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value={0}>啟用</Radio>
            <Radio value={1}>停用</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="範本名稱"
          name="name"
          rules={[{ required: true, message: "請輸入範本名稱" }]}
        >
          <Input placeholder={"範本名稱"} />
        </Form.Item>
        <Form.Item
          label="內容"
          name="content"
          rules={[{ required: true, message: "請輸入內容" }]}
        >
          <Input.TextArea rows={6} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
