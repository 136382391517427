import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { useMount } from "react-use";
import styled from "styled-components";
import { getChannels } from "../../api/channelAPI";
import { CreateGroupModal } from "./CreateGroupModal";
import { DisplayGroupModal } from "./DisplayGroupModal";
import { EditGroupModal } from "./EditGroupModal";
import { GroupSearchCriteria } from "./GroupSearchCriteria";
import { GroupTable } from "./GroupTable";

export const GroupManagement: FunctionComponent = () => {
  const dispatch = useDispatch();

  useMount(() => {
    dispatch(getChannels(1));
  });

  return (
    <Wrapper>
      <GroupSearchCriteria />
      <GroupTable />
      <CreateGroupModal />
      <EditGroupModal />
      <DisplayGroupModal />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
