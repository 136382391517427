import { List, Modal } from "antd";
import _ from "lodash";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { notificationReportSlice } from "../../store/notificationReportSlice";
import { displaySendStatus } from "../../utils/displaySendStatus";
import { formatTime } from "../../utils/formatTime";
import { Label, Value, Wrapper } from "../common/displayElement";

export const DisplayNotificationReportModal: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const displayNotificationReport = useAppSelector(
    (state) => state.notificationReport.displayNotificationReport
  );

  const { displayModalVisible } = useAppSelector(
    (state) => state.notificationReport.modal
  );

  const handleOk = () => {
    dispatch(notificationReportSlice.actions.toggleDisplayModal());
  };

  const handleClose = () => {
    dispatch(
      notificationReportSlice.actions.setDisplayNotificationReport(null)
    );
  };

  const failureStatus = !_.isEmpty(displayNotificationReport?.failures);

  return (
    <Modal
      title="發送通知報告"
      visible={displayModalVisible}
      onCancel={handleOk}
      okButtonProps={{
        style: { display: "none" },
      }}
      cancelText={"返回"}
      destroyOnClose={true}
      afterClose={handleClose}
    >
      <Wrapper>
        <Label>發送編號：</Label>
        <Value>{displayNotificationReport?.id}</Value>
      </Wrapper>
      <Wrapper>
        <Label>通知名稱：</Label>
        <Value>{displayNotificationReport?.notificationName}</Value>
      </Wrapper>
      <Wrapper>
        <Label>發送⼈員：</Label>
        <Value>{displayNotificationReport?.userName}</Value>
      </Wrapper>
      <Wrapper>
        <Label>發送⽇期：</Label>
        <Value>{formatTime(displayNotificationReport?.sentAt)}</Value>
      </Wrapper>
      <Wrapper>
        <Label>執⾏結果：</Label>
        <Value>{displaySendStatus(displayNotificationReport?.result)}</Value>
      </Wrapper>
      {failureStatus && (
        <ListWrapper>
          <Label>發送通知錯誤明細：</Label>
          <List
            size="small"
            bordered
            dataSource={displayNotificationReport?.failures}
            renderItem={(item) => (
              <List.Item>
                <Failure>
                  <span>交談名稱：{item.channelName}</span>|
                  <span>
                    發送狀態：
                    {displaySendStatus(item.sendStatus)}
                  </span>
                </Failure>
              </List.Item>
            )}
          />
        </ListWrapper>
      )}
    </Modal>
  );
};

const ListWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Failure = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
