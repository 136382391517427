import { EditOutlined } from "@ant-design/icons";
import { Table } from "antd";
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { getGroups } from "../../api/groupAPI";
import { Function } from "../../constants/Function";
import { pageSize } from "../../constants/pagination";
import { Permission } from "../../constants/Permission";
import { AppContext } from "../../core/api/AppContext";
import { usePermission } from "../../hooks/usePermissions";
import { groupSlice } from "../../store/groupSlice";
import { useAppSelector } from "../../store/hooks";
import { Group } from "../../types/Group";
import { displayStatus } from "../../utils/displayStatus";
import { formatTime } from "../../utils/formatTime";
import { getOrderBy } from "../../utils/getOrderBy";
import { showTotalData } from "../../utils/showTotalData";
import { ResourceName } from "../common/ResourceName";
import { TableActionWrapper } from "../common/TableActionWrapper";

export const GroupTable: FunctionComponent = () => {
  const groupManagementPermission = usePermission(Function.Group);
  const dispatch = useDispatch();
  const { groups, searchParams } = useAppSelector((state) => state.group);

  const handleEdit = (group: Group): void => {
    AppContext.ApiExecutor.getGroup(group.id).then(({ data }) => {
      dispatch(groupSlice.actions.setEditingGroup(data));
      dispatch(groupSlice.actions.toggleEditModal());
    });
  };

  const handleDisplay = (group: Group): void => {
    AppContext.ApiExecutor.getGroup(group.id).then(({ data }) => {
      dispatch(groupSlice.actions.setDisplayGroup(data));
      dispatch(groupSlice.actions.toggleDisplayModal());
    });
  };

  const onFilterChange = (
    config: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<Group> | SorterResult<Group>[]
  ) => {
    const orderBy = getOrderBy<Group>(sorter);
    dispatch(getGroups(config.current ?? 1, searchParams.filter, orderBy));
  };

  return (
    <Table<Group>
      tableLayout={"fixed"}
      loading={groups.fetching}
      dataSource={groups.list}
      scroll={{ y: 800, x: 700 }}
      onChange={onFilterChange}
      pagination={{
        pageSize,
        showSizeChanger: false,
        total: groups.total,
        current: groups.page,
        showTotal: () => showTotalData(groups.total),
      }}
      rowKey={"id"}
    >
      <Table.Column<Group>
        sorter={true}
        title="分群名稱"
        dataIndex="name"
        width={150}
        render={(name: string, data) => (
          <ResourceName onClick={() => handleDisplay(data)}>
            {name}
          </ResourceName>
        )}
      />
      <Table.Column
        sorter={true}
        title="說明"
        dataIndex="description"
        width={150}
      />
      <Table.Column
        sorter={true}
        title="更新人員"
        dataIndex="updatedUsername"
        width={150}
      />
      <Table.Column
        sorter={true}
        title="更新時間"
        dataIndex="updatedAt"
        width={200}
        align={"center"}
        render={(e: string) => formatTime(e)}
      />
      <Table.Column<Group>
        sorter={true}
        title="狀態"
        dataIndex="status"
        align={"center"}
        width={100}
        render={(status: number) => displayStatus(status)}
      />
      <Table.Column<Group>
        title="操作"
        dataIndex="id"
        align={"center"}
        width={100}
        render={(id, data) => {
          return (
            <TableActionWrapper>
              {groupManagementPermission.includes(Permission.Edit) && (
                <EditOutlined onClick={() => handleEdit(data)} />
              )}
            </TableActionWrapper>
          );
        }}
      />
    </Table>
  );
};
