import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchParams } from "../types/api";
import { GetEmailsResponse, Email, EmailState } from "../types/Email";

const initialState: EmailState = {
  modal: {
    createModalVisible: false,
    editModalVisible: false,
    displayModalVisible: false
  },
  emails: {
    list: [],
    fetching: false,
    total: 0,
    totalPages: 0,
    page: 0
  },
  editingEmail: null,
  displayEmail: null,
  searchParams: {
    page: 1,
    filter: "",
    orderBy: ""
  }
};

export const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    fetchingEmails: (state) => {
      state.emails.fetching = true;
    },
    fetchEmailsSuccess: (state, action: PayloadAction<GetEmailsResponse>) => {
      state.emails.fetching = false;
      state.emails.list = action.payload.emails;
      state.emails.total = action.payload.count;
      state.emails.totalPages = action.payload.totalPages;
    },
    fetchEmailsFail: (state) => {
      state.emails.fetching = false;
      state.emails.list = [];
    },
    setEditingEmail: (state, action: PayloadAction<Nullable<Email>>) => {
      state.editingEmail = action.payload;
    },
    setDisplayEmail: (state, action: PayloadAction<Nullable<Email>>) => {
      state.displayEmail = action.payload;
    },
    toggleCreateModal: (state) => {
      state.modal.createModalVisible = !state.modal.createModalVisible;
    },
    toggleEditModal: (state) => {
      state.modal.editModalVisible = !state.modal.editModalVisible;
    },
    toggleDisplayModal: (state) => {
      state.modal.displayModalVisible = !state.modal.displayModalVisible;
    },
    changeCurrentPage: (state, action: PayloadAction<number>) => {
      state.emails.page = action.payload;
    },
    setSearchParams: (state, action: PayloadAction<SearchParams>) => {
      state.searchParams = action.payload;
    }
  }
});
