import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { DisplayJiraReportModal } from "./DisplayJiraReportModal";
import { JiraReportSearchCriteria } from "./JiraReportSearchCriteria";
import { JiraReportTable } from "./JiraReportTable";

export const JiraReport: FunctionComponent = () => {
  return (
    <Wrapper>
      <JiraReportSearchCriteria />
      <JiraReportTable />
      <DisplayJiraReportModal />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
