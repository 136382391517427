import { EditOutlined } from "@ant-design/icons";
import { Table } from "antd";
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { getChannels } from "../../api/channelAPI";
import { Function } from "../../constants/Function";
import { pageSize } from "../../constants/pagination";
import { Permission } from "../../constants/Permission";
import { usePermission } from "../../hooks/usePermissions";
import { channelSlice } from "../../store/channelSlice";
import { useAppSelector } from "../../store/hooks";
import { Channel } from "../../types/Channel";
import { displayBotType } from "../../utils/displayBotType";
import { displayStatus } from "../../utils/displayStatus";
import { formatTime } from "../../utils/formatTime";
import { getOrderBy } from "../../utils/getOrderBy";
import { showTotalData } from "../../utils/showTotalData";
import { ResourceName } from "../common/ResourceName";
import { TableActionWrapper } from "../common/TableActionWrapper";

export const ChannelTable: FunctionComponent = () => {
  const channelManagementPermission = usePermission(Function.Channel);
  const dispatch = useDispatch();
  const { channels, searchParams } = useAppSelector((state) => state.channel);

  const handleEdit = (channel: Channel): void => {
    dispatch(channelSlice.actions.setEditingChannel(channel));
    dispatch(channelSlice.actions.toggleEditModal());
  };

  const handleDisplay = (channel: Channel): void => {
    dispatch(channelSlice.actions.setDisplayChannel(channel));
    dispatch(channelSlice.actions.toggleDisplayModal());
  };

  const onFilterChange = (
    config: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<Channel> | SorterResult<Channel>[]
  ) => {
    const orderBy = getOrderBy<Channel>(sorter);
    dispatch(getChannels(config.current ?? 1, searchParams.filter, orderBy));
  };

  return (
    <Table<Channel>
      tableLayout={"fixed"}
      loading={channels.fetching}
      dataSource={channels.list}
      scroll={{ y: 800, x: 700 }}
      onChange={onFilterChange}
      pagination={{
        pageSize,
        showSizeChanger: false,
        total: channels.total,
        current: channels.page,
        showTotal: () => showTotalData(channels.total),
      }}
      rowKey={"id"}
    >
      <Table.Column<Channel>
        sorter={true}
        title="交談名稱"
        dataIndex="name"
        width={120}
        render={(name: string, data) => (
          <ResourceName onClick={() => handleDisplay(data)}>
            {name}
          </ResourceName>
        )}
      />
      <Table.Column<Channel>
        sorter={true}
        title="交談編號"
        dataIndex="id"
        width={350}
        ellipsis={true}
      />
      <Table.Column
        sorter={true}
        title="總社"
        dataIndex="headOffice"
        ellipsis={true}
        width={150}
      />
      <Table.Column
        sorter={true}
        title="總社層級"
        dataIndex="tier"
        align={"center"}
        width={120}
      />
      <Table.Column
        sorter={true}
        title="MA"
        dataIndex="masterAgents"
        ellipsis={true}
        width={150}
      />
      <Table.Column
        sorter={true}
        title="來源"
        dataIndex="botType"
        align={"center"}
        width={100}
        render={(botType: number) => displayBotType(botType)}
      />
      <Table.Column
        sorter={true}
        title="新增者"
        dataIndex="createdUsername"
        width={200}
        align={"center"}
      />
      <Table.Column
        sorter={true}
        title="新增時間"
        dataIndex="createdAt"
        width={200}
        align={"center"}
        render={(e: string) => formatTime(e)}
      />
      <Table.Column
        sorter={true}
        title="更新時間"
        dataIndex="updatedAt"
        width={200}
        align={"center"}
        render={(e: string) => formatTime(e)}
      />
      <Table.Column<Channel>
        sorter={true}
        title="狀態"
        dataIndex="status"
        align={"center"}
        width={100}
        render={(status: number) => displayStatus(status)}
      />
      <Table.Column<Channel>
        title="操作"
        dataIndex="id"
        align={"center"}
        width={100}
        render={(id, data) => {
          return (
            <TableActionWrapper>
              {channelManagementPermission.includes(Permission.Edit) && (
                <EditOutlined onClick={() => handleEdit(data)} />
              )}
            </TableActionWrapper>
          );
        }}
      />
    </Table>
  );
};
