import { Button, Form, Input } from "antd";
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { useMount } from "react-use";
import styled from "styled-components";
import { getJiraReports } from "../../api/jiraReportAPI";
import { formatFuzzySearch } from "../../utils/formatFuzzySearch";
import { formatSearchFilter } from "../../utils/formatSearchFilter";

export const JiraReportSearchCriteria: FunctionComponent = () => {
  const [form] = Form.useForm<{
    createdUserName?: string;
    channelName?: string;
  }>();
  const dispatch = useDispatch();

  const fetch = () => {
    const values = form.getFieldsValue();

    const filter = formatSearchFilter([
      formatFuzzySearch(["createdUserName"], values.createdUserName),
      formatFuzzySearch(["channelName"], values.channelName),
    ]);

    dispatch(getJiraReports(1, filter));
  };

  useMount(() => {
    fetch();
  });

  return (
    <ActionWrapper>
      <Form form={form} layout={"inline"}>
        <Form.Item label="交談名稱" name="channelName">
          <Input
            allowClear
            placeholder={"請輸入交談名稱"}
            style={{ width: 200 }}
          />
        </Form.Item>
        <Form.Item label="開單⼈員" name="createdUserName">
          <Input
            allowClear
            placeholder={"請輸入開單⼈員"}
            style={{ width: 200 }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={fetch} htmlType="submit">
            搜尋
          </Button>
        </Form.Item>
      </Form>
    </ActionWrapper>
  );
};

const ActionWrapper = styled.div`
  margin: 0 0 24px 0;
  display: flex;
  justify-content: space-between;
`;
