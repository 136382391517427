import { Table } from "antd";
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { getJiraReports } from "../../api/jiraReportAPI";
import { pageSize } from "../../constants/pagination";
import { useAppSelector } from "../../store/hooks";
import { jiraReportSlice } from "../../store/jiraReportSlice";
import { JiraReport } from "../../types/JiraReport";
import { formatTime } from "../../utils/formatTime";
import { getOrderBy } from "../../utils/getOrderBy";
import { showTotalData } from "../../utils/showTotalData";
import { ResourceName } from "../common/ResourceName";

export const JiraReportTable: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { jiraReports, searchParams } = useAppSelector(
    (state) => state.jiraReport
  );

  const onFilterChange = (
    config: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<JiraReport> | SorterResult<JiraReport>[]
  ) => {
    const orderBy = getOrderBy<JiraReport>(sorter);
    dispatch(getJiraReports(config.current ?? 1, searchParams.filter, orderBy));
  };

  const handleDisplay = (jiraReport: JiraReport): void => {
    dispatch(jiraReportSlice.actions.setDisplayJiraReport(jiraReport));
    dispatch(jiraReportSlice.actions.toggleDisplayModal());
  };

  return (
    <>
      <Table<JiraReport>
        tableLayout={"auto"}
        loading={jiraReports.fetching}
        dataSource={jiraReports.list}
        scroll={{ y: 800, x: 700 }}
        onChange={onFilterChange}
        pagination={{
          pageSize,
          showSizeChanger: false,
          total: jiraReports.total,
          current: jiraReports.page,
          showTotal: () => showTotalData(jiraReports.total),
        }}
        rowKey={"id"}
      >
        <Table.Column<JiraReport>
          title="標題"
          dataIndex="title"
          sorter={true}
          width={150}
          render={(name: string, data) => (
            <ResourceName onClick={() => handleDisplay(data)}>
              {name}
            </ResourceName>
          )}
        />
        <Table.Column<JiraReport>
          title="Jira 單號"
          dataIndex="key"
          sorter={true}
          width={100}
        />
        <Table.Column<JiraReport>
          title="交談名稱"
          dataIndex="channelName"
          sorter={true}
          width={150}
        />
        <Table.Column<JiraReport>
          title="開單人員"
          dataIndex="createdUserName"
          sorter={true}
          width={150}
        />
        <Table.Column
          sorter={true}
          title="新增日期"
          dataIndex="createdAt"
          width={200}
          align={"center"}
          render={(e: string) => formatTime(e)}
        />
      </Table>
    </>
  );
};
