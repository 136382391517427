import { Modal } from "antd";
import React, { FunctionComponent } from "react";
import { channelSlice } from "../../store/channelSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { displayBotType } from "../../utils/displayBotType";
import { displayStatus } from "../../utils/displayStatus";
import { formatTime } from "../../utils/formatTime";
import { Label, Value, Wrapper } from "../common/displayElement";

export const DisplayChannelModal: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const displayChannel = useAppSelector(
    (state) => state.channel.displayChannel
  );

  const { displayModalVisible } = useAppSelector(
    (state) => state.channel.modal
  );

  const handleOk = () => {
    dispatch(channelSlice.actions.toggleDisplayModal());
  };

  const handleClose = () => {
    dispatch(channelSlice.actions.setDisplayChannel(null));
  };

  return (
    <Modal
      title="交談"
      visible={displayModalVisible}
      onOk={handleOk}
      onCancel={handleOk}
      cancelButtonProps={{ style: { display: "none" } }}
      okText={"返回"}
      destroyOnClose={true}
      afterClose={handleClose}
    >
      <Wrapper>
        <Label>交談名稱：</Label>
        <Value>{displayChannel?.name}</Value>
      </Wrapper>
      <Wrapper>
        <Label>總社：</Label>
        <Value>{displayChannel?.headOffice}</Value>
      </Wrapper>
      <Wrapper>
        <Label>總社層級：</Label>
        <Value>{displayChannel?.tier}</Value>
      </Wrapper>
      <Wrapper>
        <Label>MA：</Label>
        <Value>{displayChannel?.masterAgents}</Value>
      </Wrapper>
      <Wrapper>
        <Label>狀態：</Label>
        <Value>{displayStatus(displayChannel?.status)}</Value>
      </Wrapper>
      <Wrapper>
        <Label>來源：</Label>
        <Value>{displayBotType(displayChannel?.botType)}</Value>
      </Wrapper>
      <Wrapper>
        <Label>說明：</Label>
        <Value>{displayChannel?.description}</Value>
      </Wrapper>
      <Wrapper>
        <Label>更新人員：</Label>
        <Value>{displayChannel?.updatedUsername}</Value>
      </Wrapper>
      <Wrapper>
        <Label>更新時間：</Label>
        <Value>{formatTime(displayChannel?.updatedAt)}</Value>
      </Wrapper>
    </Modal>
  );
};
