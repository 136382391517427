import { Form, Input, Modal, Radio, Select, Spin } from "antd";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useToggle } from "react-use";
import { refreshGroups } from "../../api/groupAPI";
import { AppContext } from "../../core/api/AppContext";
import { groupSlice } from "../../store/groupSlice";
import { useAppSelector } from "../../store/hooks";
import { GroupFormFields } from "../../types/Group";
import { getChannelByID } from "../../utils/getChannelByID";
import { useChannels } from "../../hooks/useChannels";

export const CreateGroupModal: FunctionComponent = () => {
  const [form] = Form.useForm<GroupFormFields>();
  const [loading, setLoading] = useToggle(false);
  const dispatch = useDispatch();
  const { createModalVisible } = useAppSelector((state) => state.group.modal);
  const [headOffices, setHeadOffices] = useState("");
  const {
    channels,
    loading: loadingChannels,
    loadingRef: loadingRefChannel,
    hasNextPage: hasNextChannelPage,
    getChannelsByName,
  } = useChannels(true);

  const handleOk = () => {
    form.validateFields().then((values) => {
      const payload = {
        ...values,
        channels: values.channels,
      };

      setLoading(true);
      AppContext.ApiExecutor.createGroup(payload)
        .then(() => {
          dispatch(groupSlice.actions.toggleCreateModal());
          dispatch(refreshGroups());
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const handleCancel = () => {
    dispatch(groupSlice.actions.toggleCreateModal());
  };

  const handleClose = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (!createModalVisible) {
      setHeadOffices("");
    }
  }, [createModalVisible]);

  const onSelectChannel = (selectedChannels: string[]): void => {
    const headOffices = selectedChannels
      .map((id) => getChannelByID(id, channels)?.headOffice)
      .filter((headOffice) => !!headOffice)
      .join(", ");

    setHeadOffices(headOffices);
  };

  const onSearchChannel = (name: string) => {
    getChannelsByName(name);
  };

  return (
    <Modal
      title="新增分群"
      visible={createModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"儲存"}
      confirmLoading={loading}
      cancelText={"取消"}
      destroyOnClose={true}
      afterClose={handleClose}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ status: 0 }}
        form={form}
      >
        <Form.Item
          label="分群名稱"
          name="name"
          rules={[{ required: true, message: "請輸入分群名稱" }]}
        >
          <Input placeholder={"分群名稱"} />
        </Form.Item>
        <Form.Item label="交談清單" name="channels">
          <Select
            mode={"multiple"}
            onChange={onSelectChannel}
            onSearch={onSearchChannel}
            filterOption={false}
            notFoundContent={loadingChannels ? <Spin size="small" /> : null}
          >
            {channels.map((channel) => (
              <Select.Option key={channel.id} value={channel.id}>
                {channel.name}
              </Select.Option>
            ))}
            {(loadingChannels || hasNextChannelPage) && (
              <Select.Option value={"ref"}>
                <div ref={loadingRefChannel}>
                  <Spin />
                </div>
              </Select.Option>
            )}
          </Select>
        </Form.Item>
        <Form.Item label="總社">
          <Input value={headOffices} disabled />
        </Form.Item>
        <Form.Item label="說明" name="description">
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item label="狀態" name="status" rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value={0}>啟用</Radio>
            <Radio value={1}>停用</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};
