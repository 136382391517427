import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Modal, notification, Table } from "antd";
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { getUsers, refreshUsers } from "../../api/userAPI";
import { Function } from "../../constants/Function";
import { pageSize } from "../../constants/pagination";
import { Permission } from "../../constants/Permission";
import { AppContext } from "../../core/api/AppContext";
import { usePermission } from "../../hooks/usePermissions";
import { useAppSelector } from "../../store/hooks";
import { userSlice } from "../../store/userSlice";
import { User } from "../../types/User";
import { displayRole } from "../../utils/displayRole";
import { displayStatus } from "../../utils/displayStatus";
import { formatTime } from "../../utils/formatTime";
import { getOrderBy } from "../../utils/getOrderBy";
import { showTotalData } from "../../utils/showTotalData";
import { ResourceName } from "../common/ResourceName";
import { TableActionWrapper } from "../common/TableActionWrapper";

export const UserTable: FunctionComponent = () => {
  const userManagementPermission = usePermission(Function.UserManagement);
  const dispatch = useDispatch();
  const { users, searchParams } = useAppSelector((state) => state.user);
  const { rolePermission } = useAppSelector((state) => state.auth);

  const handleEdit = (user: User): void => {
    dispatch(userSlice.actions.setEditingUser(user));
    dispatch(userSlice.actions.toggleEditModal());
  };

  const handleDelete = (user: User): void => {
    Modal.confirm({
      title: "刪除使用者",
      icon: <ExclamationCircleOutlined />,
      content: `確定要刪除使用者「${user.idFromOkta}」？`,
      onOk() {
        AppContext.ApiExecutor.deleteUser(user.id)
          .then(() => {
            notification.success({
              message: "刪除成功",
              placement: "bottomRight",
            });
            dispatch(refreshUsers());
          })
          .catch(() => {
            notification.warn({
              message: "刪除失敗",
              placement: "bottomRight",
            });
          });
      },
    });
  };

  const handleDisplay = (user: User): void => {
    dispatch(userSlice.actions.setDisplayUser(user));
    dispatch(userSlice.actions.toggleDisplayModal());
  };

  const onFilterChange = (
    pagination: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<User> | SorterResult<User>[]
  ) => {
    const orderBy = getOrderBy<User>(sorter);
    dispatch(getUsers(pagination.current ?? 1, searchParams.filter, orderBy));
  };

  return (
    <Table<User>
      tableLayout={"auto"}
      loading={users.fetching}
      dataSource={users.list}
      scroll={{ y: 800, x: 700 }}
      onChange={onFilterChange}
      pagination={{
        pageSize,
        showSizeChanger: false,
        total: users.total,
        current: users.page,
        showTotal: () => showTotalData(users.total),
      }}
      rowKey={"id"}
    >
      <Table.Column<User>
        sorter={true}
        title="使用者帳號"
        dataIndex="idFromOkta"
        width={150}
        render={(idFromOkta: string, data) => (
          <ResourceName onClick={() => handleDisplay(data)}>
            {idFromOkta}
          </ResourceName>
        )}
      />
      <Table.Column
        sorter={true}
        title="角色"
        dataIndex="role"
        render={(role) => displayRole(role)}
      />
      <Table.Column
        sorter={true}
        title="更新人員"
        dataIndex="updatedUsername"
        width={150}
      />
      <Table.Column
        sorter={true}
        title="更新時間"
        dataIndex="updatedAt"
        width={200}
        align={"center"}
        render={(e: string) => formatTime(e)}
      />
      <Table.Column<User>
        sorter={true}
        title="狀態"
        dataIndex="status"
        align={"center"}
        width={100}
        render={(status: number) => displayStatus(status)}
      />
      <Table.Column<User>
        title="操作"
        dataIndex="id"
        align={"center"}
        width={100}
        render={(id, data) => {
          if (!rolePermission) {
            return;
          }
          return (
            <TableActionWrapper>
              {rolePermission?.role < data.role &&
                userManagementPermission.includes(Permission.Edit) && (
                  <EditOutlined onClick={() => handleEdit(data)} />
                )}
              {rolePermission?.role < data.role &&
                userManagementPermission.includes(Permission.Delete) && (
                  <DeleteOutlined onClick={() => handleDelete(data)} />
                )}
            </TableActionWrapper>
          );
        }}
      />
    </Table>
  );
};
