import { AutoComplete, Form, Input, Modal, Radio } from "antd";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useToggle } from "react-use";
import { AppContext } from "../../core/api/AppContext";
import { emailSlice } from "../../store/emailSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { EmailFields } from "../../types/Email";
import { refreshEmails } from "../../api/emailAPI";

export const EditEmailModal: FunctionComponent = () => {
  const [form] = Form.useForm<EmailFields>();
  const [loading, setLoading] = useToggle(false);
  const [initialValues, setInitialValues] = useState<EmailFields>();
  const dispatch = useAppDispatch();
  const editingEmail = useAppSelector((state) => state.email.editingEmail);
  const { editModalVisible } = useAppSelector((state) => state.email.modal);
  const [headOffices, setHeadOffices] = useState("");
  const headOffice = useAppSelector((state) => state.headOffice);

  const handleOk = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      AppContext.ApiExecutor.updateEmail(editingEmail!.id, values)
        .then(() => {
          dispatch(emailSlice.actions.toggleEditModal());
          dispatch(refreshEmails());
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const handleCancel = () => {
    dispatch(emailSlice.actions.toggleEditModal());
  };

  const handleClose = () => {
    dispatch(emailSlice.actions.setEditingEmail(null));
  };

  useEffect(() => {
    if (editingEmail && editModalVisible) {
      setHeadOffices(editingEmail.headOffice);
    }
  }, [editModalVisible]);

  useEffect(() => {
    setInitialValues(editingEmail || undefined);
  }, [editingEmail]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues]);

  return (
    <Modal
      title="編輯分群"
      visible={editModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"儲存"}
      cancelText={"取消"}
      destroyOnClose={true}
      afterClose={handleClose}
      confirmLoading={loading}
    >
      {editModalVisible && (
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={initialValues}
          form={form}
        >
          <Form.Item
            label="Email"
            name="address"
            rules={[{ type: "email", required: true, message: "請輸入 Email" }]}
          >
            <Input placeholder={"example@domain.com"} />
          </Form.Item>
          <Form.Item
            label="總社"
            name="headOffice"
            rules={[{ required: true, message: "請輸入總社" }]}
          >
            <AutoComplete placeholder="請輸入總社" style={{ width: 200 }}>
              {headOffice.emails.map((office) => (
                <AutoComplete.Option value={office} key={office}>
                  {office}
                </AutoComplete.Option>
              ))}
            </AutoComplete>
          </Form.Item>
          <Form.Item label="說明" name="description">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item label="狀態" name="status" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={0}>啟用</Radio>
              <Radio value={1}>停用</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
