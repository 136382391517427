import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 12px 0;
`;

export const Label = styled.div`
  min-width: 140px;
  text-align: right;
  margin: 0 12px 0 0;
`;

export const Value = styled.div`
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
`;
