import { Modal } from "antd";
import React, { FunctionComponent } from "react";
import { groupSlice } from "../../store/groupSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { displayStatus } from "../../utils/displayStatus";
import { formatTime } from "../../utils/formatTime";
import { Label, Value, Wrapper } from "../common/displayElement";
import { useChannels } from "../../hooks/useChannels";

export const DisplayGroupModal: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const displayGroup = useAppSelector((state) => state.group.displayGroup);
  const { channels } = useChannels(true);

  const { displayModalVisible } = useAppSelector((state) => state.group.modal);

  const handleOk = () => {
    dispatch(groupSlice.actions.toggleDisplayModal());
  };

  const handleClose = () => {
    dispatch(groupSlice.actions.setDisplayGroup(null));
  };

  return (
    <Modal
      title="分群內容"
      visible={displayModalVisible}
      onOk={handleOk}
      onCancel={handleOk}
      cancelButtonProps={{ style: { display: "none" } }}
      okText={"返回"}
      destroyOnClose={true}
      afterClose={handleClose}
    >
      <Wrapper>
        <Label>分群名稱：</Label>
        <Value>{displayGroup?.name}</Value>
      </Wrapper>
      <Wrapper>
        <Label>交談清單：</Label>
        <Value>
          {displayGroup?.channels
            .map(
              (channelID) =>
                channels.find((channel) => channel.id === channelID)?.name
            )
            .join(", ")}
        </Value>
      </Wrapper>
      <Wrapper>
        <Label>總社：</Label>
        <Value>{displayGroup?.headOffice}</Value>
      </Wrapper>
      <Wrapper>
        <Label>狀態：</Label>
        <Value>{displayStatus(displayGroup?.status)}</Value>
      </Wrapper>
      <Wrapper>
        <Label>分群描述：</Label>
        <Value>{displayGroup?.description}</Value>
      </Wrapper>
      <Wrapper>
        <Label>更新人員：</Label>
        <Value>{displayGroup?.updatedUsername}</Value>
      </Wrapper>
      <Wrapper>
        <Label>更新時間：</Label>
        <Value>{formatTime(displayGroup?.updatedAt)}</Value>
      </Wrapper>
    </Modal>
  );
};
