import { Table } from "antd";
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { getSLAReports } from "../../api/slaReportAPI";
import { pageSize } from "../../constants/pagination";
import { useAppSelector } from "../../store/hooks";
import { SLAReport } from "../../types/SLAReport";
import { formatTime } from "../../utils/formatTime";
import { getOrderBy } from "../../utils/getOrderBy";
import { showTotalData } from "../../utils/showTotalData";

export const SLAReportTable: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { slaReports, searchParams } = useAppSelector(
    (state) => state.slaReport
  );

  const onFilterChange = (
    config: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<SLAReport> | SorterResult<SLAReport>[]
  ) => {
    const orderBy = getOrderBy<SLAReport>(sorter);
    dispatch(getSLAReports(config.current ?? 1, searchParams.filter, orderBy));
  };

  return (
    <>
      <Table<SLAReport>
        tableLayout={"auto"}
        loading={slaReports.fetching}
        dataSource={slaReports.list}
        scroll={{ y: 800, x: 700 }}
        onChange={onFilterChange}
        pagination={{
          pageSize,
          showSizeChanger: false,
          total: slaReports.total,
          current: slaReports.page,
          showTotal: () => showTotalData(slaReports.total),
        }}
        rowKey={"id"}
      >
        <Table.Column<SLAReport>
          title="通知名稱"
          dataIndex="name"
          sorter={true}
          width={150}
        />
        <Table.Column
          sorter={true}
          title="發送時間"
          dataIndex="alertedAt"
          width={200}
          align={"center"}
          render={(e: string) => formatTime(e)}
        />
      </Table>
    </>
  );
};
