import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./authSlice";
import { channelSlice } from "./channelSlice";
import { groupSlice } from "./groupSlice";
import { headOfficeSlice } from "./headOfficeSlice";
import { jiraReportSlice } from "./jiraReportSlice";
import { messageTemplateSlice } from "./messageTemplateSlice";
import { notificationReportSlice } from "./notificationReportSlice";
import { notificationSlice } from "./notificationSlice";
import { slaReportSlice } from "./slaReportSlice";
import { slaSlice } from "./slaSlice";
import { tabSlice } from "./tabSlice";
import { userSlice } from "./userSlice";
import { emailSlice } from "./emailSlice";

export const store = configureStore({
  reducer: {
    tab: tabSlice.reducer,
    messageTemplate: messageTemplateSlice.reducer,
    notification: notificationSlice.reducer,
    notificationReport: notificationReportSlice.reducer,
    channel: channelSlice.reducer,
    group: groupSlice.reducer,
    headOffice: headOfficeSlice.reducer,
    sla: slaSlice.reducer,
    slaReport: slaReportSlice.reducer,
    jiraReport: jiraReportSlice.reducer,
    user: userSlice.reducer,
    auth: authSlice.reducer,
    email: emailSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
