import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchParams } from "../types/api";
import { GetSLAReportsResponse, SLAReportState } from "../types/SLAReport";

const initialState: SLAReportState = {
  slaReports: {
    list: [],
    fetching: false,
    total: 0,
    totalPages: 0,
    page: 0,
  },
  searchParams: {
    page: 1,
    filter: "",
    orderBy: "",
  },
};

export const slaReportSlice = createSlice({
  name: "slaReport",
  initialState,
  reducers: {
    fetchingSLAReports: (state) => {
      state.slaReports.fetching = true;
    },
    fetchSLAReportsSuccess: (
      state,
      action: PayloadAction<GetSLAReportsResponse>
    ) => {
      state.slaReports.fetching = false;
      state.slaReports.list = action.payload.slaReports;
      state.slaReports.total = action.payload.count;
      state.slaReports.totalPages = action.payload.totalPages;
    },
    fetchSLAReportsFail: (state) => {
      state.slaReports.fetching = false;
      state.slaReports.list = [];
    },
    changeCurrentPage: (state, action: PayloadAction<number>) => {
      state.slaReports.page = action.payload;
    },
    setSearchParams: (state, action: PayloadAction<SearchParams>) => {
      state.searchParams = action.payload;
    },
  },
});
