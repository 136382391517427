import { HttpTransportType, HubConnectionBuilder } from "@microsoft/signalr";
import { Button, notification } from "antd";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMount, useUpdateEffect } from "react-use";
import styled from "styled-components";
import slaAudio from "../audio/sla.wav";
import { AppContext } from "../core/api/AppContext";
import { goToPage } from "../goToPage";
import { authSlice } from "../store/authSlice";
import { useAppSelector } from "../store/hooks";
import { ChannelManagement } from "./channel-management/ChannelManagement";
import { GroupManagement } from "./group-management/GroupManagement";
import { JiraReport } from "./jira-report/JiraReport";
import { AppLayout } from "./layout/AppLayout";
import { NotificationManagement } from "./notification-management/NotificationManagement";
import { NotificationReport } from "./notification-report/NotificationReport";
import { SLAManagement } from "./sla-management/SLAManagement";
import { SLAReport } from "./sla-report/SLAReport";
import { TemplateManagement } from "./template-management/TemplateManagement";
import { UserManagement } from "./user-management/UserManagement";
import { config } from "../core/config";
import { changeTab } from "../store/tabSlice";
import { getFunctionSideKey } from "../utils/getFunctionSideKey";
import { EmailManagement } from "./email-management/EmailManagement";

const SnoozeNotifyKey = "SnoozeNotifyKey";
const JiraNotifyKey = "JiraNotifyKey";
const JiraTicketNotifyKey = "JiraTicketNotifyKey";

export const App: FunctionComponent = () => {
  const { currentTab } = useAppSelector((state) => state.tab);
  const dispatch = useDispatch();
  const [connection, setConnection] = useState<any>(null);
  const [slaNotifyAudio] = useState(
    typeof Audio !== "undefined" ? new Audio(slaAudio) : null
  );
  const [snooze, setSnooze] = useState({
    channelName: null,
    content: "",
    conversationName: "Skype Robot Project Sync Up",
    from: "",
    intervalMinutes: 0,
  });

  const [jiraSnooze, setJiraSnooze] = useState({
    issueKey: "",
    intervalMinutes: 0,
  });

  const [jiraTicketSnooze, setJiraTicketSnooze] = useState({
    channelId: "",
    key: "",
    url: "",
  });

  const openSnoozeNotify = () => {
    slaNotifyAudio?.play().catch((e: Error) => console.warn(e));
    notification.open({
      key: SnoozeNotifyKey,
      message: "提醒通知",
      description: (
        <DescriptionWrapper>
          <Description>{snooze.conversationName}</Description>
          <Description>
            {snooze.from}: {snooze.content}
          </Description>
          <ActionWrapper>
            <span>提醒間隔時間： </span>
            <Button onClick={() => onClickSnoozeNotifyInterval(5)}>5</Button>
            <Button onClick={() => onClickSnoozeNotifyInterval(3)}>3</Button>
            <Button onClick={() => onClickSnoozeNotifyInterval(1)}>1</Button>
            <span>分鐘</span>
          </ActionWrapper>
        </DescriptionWrapper>
      ),
      onClick: () => {
        console.log("Notification Clicked!");
      },
      duration: null,
      closeIcon: null,
    });
  };

  const openJiraSnoozeNotify = () => {
    slaNotifyAudio?.play().catch((e: Error) => console.warn(e));
    notification.open({
      key: JiraNotifyKey,
      message: "JIRA 提醒通知",
      description: (
        <DescriptionWrapper>
          <Description>ISSUE: {jiraSnooze.issueKey}</Description>
          <ActionWrapper>
            <span>提醒間隔時間： </span>
            <Button onClick={() => onClickJiraSnoozeNotifyInterval(5)}>
              5
            </Button>
            <Button onClick={() => onClickJiraSnoozeNotifyInterval(3)}>
              3
            </Button>
            <Button onClick={() => onClickJiraSnoozeNotifyInterval(1)}>
              1
            </Button>
            <span>分鐘</span>
          </ActionWrapper>
        </DescriptionWrapper>
      ),
      onClick: () => {
        console.log("Notification Clicked!");
      },
      duration: null,
      closeIcon: null,
    });
  };

  const openJiraTicketSnoozeNotify = () => {
    if (!jiraTicketSnooze) {
      return;
    }
    slaNotifyAudio?.play().catch((e: Error) => console.warn(e));
    notification.open({
      key: JiraTicketNotifyKey,
      message: "JIRA Ticket 提醒通知",
      description: (
        <DescriptionWrapper>
          <Description>Jira {jiraTicketSnooze.key} 已建立</Description>
          <ActionWrapper>
            <span>是否通知： </span>
            <Button
              type={"primary"}
              onClick={() => {
                AppContext.ApiExecutor.sentJiraReportNotification(
                  jiraTicketSnooze.channelId,
                  jiraTicketSnooze.url
                ).finally(() => {
                  notification.close(JiraTicketNotifyKey);
                });
              }}
            >
              是
            </Button>
            <Button onClick={() => notification.close(JiraTicketNotifyKey)}>
              否
            </Button>
          </ActionWrapper>
        </DescriptionWrapper>
      ),
      onClick: () => {
        console.log("Notification Clicked!");
      },
      duration: null,
      closeIcon: null,
    });
  };

  const onClickSnoozeNotifyInterval = (intervalMinutes: number): void => {
    notification.close(SnoozeNotifyKey);
    setTimeout(() => {
      openSnoozeNotify();
    }, intervalMinutes * 1000 * 60);
  };

  const onClickJiraSnoozeNotifyInterval = (intervalMinutes: number): void => {
    notification.close(JiraNotifyKey);
    setTimeout(() => {
      openJiraSnoozeNotify();
    }, intervalMinutes * 1000 * 60);
  };

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${config.wsURL}/hub`, {
        transport: HttpTransportType.WebSockets,
        skipNegotiation: true,
      })
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, []);

  useMount(() => {
    AppContext.ApiExecutor.getRolePermission()
      .then(({ data }) => {
        if (data.permissions[0]) {
          dispatch(changeTab(getFunctionSideKey(data.permissions[0].function)));
        }
        dispatch(authSlice.actions.setRolePermission(data));
      })
      .catch(() => {
        goToPage("/login");
      });
  });

  useEffect(() => {
    if (connection) {
      connection.on("snooze", (data: any) => {
        setSnooze(data);
      });

      connection.on("jirasnooze", (data: any) => {
        setJiraSnooze(data);
      });

      connection.on("NotifyJiraTicket", (data: any) => {
        setJiraTicketSnooze(data);
      });

      connection
        .start()
        .then(() => {
          console.log("Connected!");
        })
        .catch((e: Error) => console.log("Connection failed: ", e));
    }
  }, [connection]);

  useUpdateEffect(() => {
    openJiraSnoozeNotify();
  }, [jiraSnooze]);

  useUpdateEffect(() => {
    openSnoozeNotify();
  }, [snooze]);

  useUpdateEffect(() => {
    openJiraTicketSnoozeNotify();
  }, [jiraTicketSnooze]);

  return (
    <AppLayout>
      {currentTab === "template" && <TemplateManagement />}
      {currentTab === "email" && <EmailManagement />}
      {currentTab === "notification" && <NotificationManagement />}
      {currentTab === "channel" && <ChannelManagement />}
      {currentTab === "group" && <GroupManagement />}
      {currentTab === "sla" && <SLAManagement />}
      {currentTab === "user" && <UserManagement />}
      {currentTab === "notificationReport" && <NotificationReport />}
      {currentTab === "slaReport" && <SLAReport />}
      {currentTab === "jiraReport" && <JiraReport />}
    </AppLayout>
  );
};

const DescriptionWrapper = styled.div``;

const Description = styled.div`
  margin: 0 0 12px 0;
`;

const ActionWrapper = styled.div`
  .ant-btn {
    margin: 0 8px 0 0;
  }
`;
