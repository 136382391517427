import { TreeDataNode } from "antd";

export const permissionTreeData: TreeDataNode[] = [
  {
    title: "使用者管理",
    key: "0",
    children: [
      { title: "瀏覽", key: "0-1" },
      { title: "新增", key: "0-2" },
      { title: "編輯", key: "0-4" },
      { title: "刪除", key: "0-8" },
    ],
  },
  {
    title: "範本管理",
    key: "1",
    children: [
      { title: "瀏覽", key: "1-1" },
      { title: "新增", key: "1-2" },
      { title: "編輯", key: "1-4" },
      { title: "刪除", key: "1-8" },
    ],
  },
  {
    title: "交談管理",
    key: "2",
    children: [
      { title: "瀏覽", key: "2-1" },
      { title: "新增", key: "2-2" },
      { title: "編輯", key: "2-4" },
      { title: "刪除", key: "2-8" },
    ],
  },
  {
    title: "分群管理",
    key: "3",
    children: [
      { title: "瀏覽", key: "3-1" },
      { title: "新增", key: "3-2" },
      { title: "編輯", key: "3-4" },
      { title: "刪除", key: "3-8" },
    ],
  },
  {
    title: "通知管理",
    key: "4",
    children: [
      { title: "瀏覽", key: "4-1" },
      { title: "新增", key: "4-2" },
      { title: "編輯", key: "4-4" },
      { title: "刪除", key: "4-8" },
    ],
  },
  {
    title: "SLA",
    key: "5",
    children: [
      { title: "瀏覽", key: "5-1" },
      { title: "新增", key: "5-2" },
      { title: "編輯", key: "5-4" },
      { title: "刪除", key: "5-8" },
    ],
  },
  // deprecated
  // {
  //   title: "Jira",
  //   key: "6",
  //   children: [
  //     { title: "瀏覽", key: "6-1" },
  //     { title: "新增", key: "6-2" },
  //     { title: "編輯", key: "6-4" },
  //     { title: "刪除", key: "6-8" },
  //   ],
  // },
  {
    title: "報表",
    key: "7",
    children: [
      { title: "瀏覽", key: "7-1" },
      { title: "新增", key: "7-2" },
      { title: "編輯", key: "7-4" },
      { title: "刪除", key: "7-8" },
    ],
  },
  {
    title: "Email",
    key: "8",
    children: [
      { title: "瀏覽", key: "8-1" },
      { title: "新增", key: "8-2" },
      { title: "編輯", key: "8-4" },
      { title: "刪除", key: "8-8" },
    ],
  },
];
