import { AppContext } from "../core/api/AppContext";
import { jiraReportSlice } from "../store/jiraReportSlice";
import { AppDispatch, RootState } from "../store/store";

export const getJiraReports =
  (page: number, filter?: string, orderBy?: string) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const searchFilter =
      filter !== undefined ? filter : getState().jiraReport.searchParams.filter;
    const searchOrderBy =
      orderBy !== undefined
        ? orderBy
        : getState().jiraReport.searchParams.orderBy;

    dispatch(
      jiraReportSlice.actions.setSearchParams({
        page,
        filter: searchFilter,
        orderBy: searchOrderBy,
      })
    );

    dispatch(jiraReportSlice.actions.fetchingJiraReports());

    AppContext.ApiExecutor.getJiraReports({
      page,
      filter: searchFilter,
      orderBy: searchOrderBy,
    })
      .then(({ data }) => {
        dispatch(jiraReportSlice.actions.fetchJiraReportsSuccess(data));
      })
      .catch(() => {
        jiraReportSlice.actions.fetchJiraReportsFail();
      })
      .finally(() => {
        dispatch(jiraReportSlice.actions.changeCurrentPage(page));
      });
  };
