import { FunctionPermission } from "../types/Auth";

export const convertPermissionsToKeys = (
  permissions: FunctionPermission[] = []
): string[] => {
  return permissions
    .map((p) => {
      return p.permission
        .toString(2)
        .padStart(4, "0")
        .split("")
        .map((permission, index) => {
          return `${p.function}-${Math.pow(2, 3 - index) * Number(permission)}`;
        });
    })
    .flat();
};
