import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchParams } from "../types/api";
import {
  GetJiraReportsResponse,
  JiraReport,
  JiraReportState,
} from "../types/JiraReport";

const initialState: JiraReportState = {
  modal: {
    createModalVisible: false,
    editModalVisible: false,
    displayModalVisible: false,
  },
  jiraReports: {
    list: [],
    fetching: false,
    total: 0,
    totalPages: 0,
    page: 0,
  },
  displayJiraReport: null,
  searchParams: {
    page: 1,
    filter: "",
    orderBy: "",
  },
};

export const jiraReportSlice = createSlice({
  name: "jiraReport",
  initialState,
  reducers: {
    fetchingJiraReports: (state) => {
      state.jiraReports.fetching = true;
    },
    fetchJiraReportsSuccess: (
      state,
      action: PayloadAction<GetJiraReportsResponse>
    ) => {
      state.jiraReports.fetching = false;
      state.jiraReports.list = action.payload.jiraReports;
      state.jiraReports.total = action.payload.count;
      state.jiraReports.totalPages = action.payload.totalPages;
    },
    fetchJiraReportsFail: (state) => {
      state.jiraReports.fetching = false;
      state.jiraReports.list = [];
    },
    setDisplayJiraReport: (
      state,
      action: PayloadAction<Nullable<JiraReport>>
    ) => {
      state.displayJiraReport = action.payload;
    },
    toggleDisplayModal: (state) => {
      state.modal.displayModalVisible = !state.modal.displayModalVisible;
    },
    changeCurrentPage: (state, action: PayloadAction<number>) => {
      state.jiraReports.page = action.payload;
    },
    setSearchParams: (state, action: PayloadAction<SearchParams>) => {
      state.searchParams = action.payload;
    },
  },
});
