import { AppContext } from "../core/api/AppContext";
import { slaReportSlice } from "../store/slaReportSlice";
import { AppDispatch, RootState } from "../store/store";

export const getSLAReports =
  (page: number, filter?: string, orderBy?: string) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const searchFilter =
      filter !== undefined ? filter : getState().slaReport.searchParams.filter;
    const searchOrderBy =
      orderBy !== undefined
        ? orderBy
        : getState().slaReport.searchParams.orderBy;

    dispatch(
      slaReportSlice.actions.setSearchParams({
        page,
        filter: searchFilter,
        orderBy: searchOrderBy,
      })
    );

    dispatch(slaReportSlice.actions.fetchingSLAReports());

    AppContext.ApiExecutor.getSLAReports({
      page,
      filter: searchFilter,
      orderBy: searchOrderBy,
    })
      .then(({ data }) => {
        dispatch(slaReportSlice.actions.fetchSLAReportsSuccess(data));
      })
      .catch(() => {
        slaReportSlice.actions.fetchSLAReportsFail();
      })
      .finally(() => {
        dispatch(slaReportSlice.actions.changeCurrentPage(page));
      });
  };
