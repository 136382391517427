import _ from "lodash";
import { BotType } from "../constants/BotType";

export const displayBotType = (botType: BotType = BotType.Skype): string => {
  const botTypeMap: Record<BotType, string> = {
    [BotType.Skype]: "Skype",
    [BotType.Telegram]: "Telegram",
  };

  return _.get(botTypeMap, botType, "");
};
