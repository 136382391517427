import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { SLAReportSearchCriteria } from "./SLAReportSearchCriteria";
import { SLAReportTable } from "./SLAReportTable";

export const SLAReport: FunctionComponent = () => {
  return (
    <Wrapper>
      <SLAReportSearchCriteria />
      <SLAReportTable />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
