import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "../types/Auth";

const initialState: AuthState = {
  rolePermission: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setRolePermission: (
      state,
      action: PayloadAction<AuthState["rolePermission"]>
    ) => {
      state.rolePermission = action.payload;
    },
  },
});
