import { Button, DatePicker, Form, Input } from "antd";
import React, { FunctionComponent } from "react";
import CsvDownloader from "react-csv-downloader";
import { useDispatch } from "react-redux";
import { useMount } from "react-use";
import styled from "styled-components";
import { getSLAReports } from "../../api/slaReportAPI";
import { AppContext } from "../../core/api/AppContext";
import { formatFuzzySearch } from "../../utils/formatFuzzySearch";
import {
  Duration,
  formatSearchDuration,
} from "../../utils/formatSearchDuration";
import { formatSearchFilter } from "../../utils/formatSearchFilter";

interface CSVResult {
  [key: string]: string;
}

export const SLAReportSearchCriteria: FunctionComponent = () => {
  const [form] = Form.useForm<{
    searchText?: string;
    duration: Duration;
  }>();
  const dispatch = useDispatch();

  const fetch = () => {
    const values = form.getFieldsValue();

    const filter = formatSearchFilter([
      formatFuzzySearch(["name"], values.searchText),
      formatSearchDuration("alertedAt", values.duration),
    ]);

    dispatch(getSLAReports(1, filter));
  };

  const download = async () => {
    const values = form.getFieldsValue();

    const filter = formatSearchFilter([
      formatFuzzySearch(["name"], values.searchText),
      formatSearchDuration("alertedAt", values.duration),
    ]);

    const { data } = await AppContext.ApiExecutor.downloadSLAReports({
      page: 1,
      filter,
      orderBy: "",
    });
    const reportRaw = data.split("\r\n").filter((row) => !!row);
    const keys = reportRaw[0].split(",");
    const reportData = reportRaw.slice(1).map((row) => row.split(","));

    const result = reportData.map((row, rowIndex) => {
      return keys.reduce<CSVResult>(
        (acc, curr: string, columnIndex) => (
          (acc[curr] = reportData[rowIndex][columnIndex]), acc
        ),
        {}
      );
    });
    return Promise.resolve(result);
  };

  useMount(() => {
    fetch();
  });

  return (
    <ActionWrapper>
      <Form form={form} layout={"inline"}>
        <Form.Item label="通知名稱" name="searchText">
          <Input
            allowClear
            placeholder={"請輸入通知名稱"}
            style={{ width: 200 }}
          />
        </Form.Item>
        <Form.Item label="通知時間" name="duration">
          <DatePicker.RangePicker allowClear />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={fetch} htmlType="submit">
            搜尋
          </Button>
        </Form.Item>
        <Form.Item>
          <CsvDownloader filename="myfile" extension=".csv" datas={download}>
            <Button type="primary" htmlType="submit">
              下載
            </Button>
          </CsvDownloader>
        </Form.Item>
      </Form>
    </ActionWrapper>
  );
};

const ActionWrapper = styled.div`
  margin: 0 0 24px 0;
  display: flex;
  justify-content: space-between;
`;
