import { Button, Form, Input, Select } from "antd";
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { useMount } from "react-use";
import styled from "styled-components";
import { getUsers } from "../../api/userAPI";
import { Function } from "../../constants/Function";
import { Permission } from "../../constants/Permission";
import { Role } from "../../constants/Role";
import { usePermission } from "../../hooks/usePermissions";
import { userSlice } from "../../store/userSlice";
import { formatFuzzySearch } from "../../utils/formatFuzzySearch";
import { formatSearchFilter } from "../../utils/formatSearchFilter";
import { formatSearchRole } from "../../utils/formatSearchRole";
import { formatSearchStatus } from "../../utils/formatSearchStatus";

export const UserSearchCriteria: FunctionComponent = () => {
  const userManagementPermission = usePermission(Function.UserManagement);
  const [form] = Form.useForm<{
    status?: number;
    idFromOkta?: string;
    content?: string;
    role?: number;
  }>();
  const dispatch = useDispatch();
  const handleCreateUser = () => {
    dispatch(userSlice.actions.toggleCreateModal());
  };

  const fetch = () => {
    const values = form.getFieldsValue();
    const filter = formatSearchFilter([
      formatFuzzySearch(["idFromOkta"], values.idFromOkta),
      formatSearchStatus(values.status),
      formatSearchRole(values.role),
    ]);
    dispatch(getUsers(1, filter));
  };

  useMount(() => {
    fetch();
  });

  return (
    <ActionWrapper>
      <Form layout={"inline"} form={form}>
        <Form.Item label="搜尋使用者帳號" name="idFromOkta">
          <Input
            allowClear
            placeholder={"請輸入使用者帳號"}
            style={{ width: 200 }}
          />
        </Form.Item>
        <Form.Item label="狀態" name="status">
          <Select allowClear placeholder="所有狀態" style={{ width: 100 }}>
            <Select.Option value={0}>啟用</Select.Option>
            <Select.Option value={1}>停用</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="角色" name="role">
          <Select allowClear placeholder="所有角色" style={{ width: 120 }}>
            <Select.Option value={Role.Admin}>管理員</Select.Option>
            <Select.Option value={Role.User}>一般使用者</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={fetch} htmlType="submit">
            搜尋
          </Button>
        </Form.Item>
      </Form>
      {userManagementPermission.includes(Permission.Add) && (
        <Button type={"primary"} onClick={handleCreateUser}>
          新增使用者
        </Button>
      )}
    </ActionWrapper>
  );
};

const ActionWrapper = styled.div`
  margin: 0 0 24px 0;
  display: flex;
  justify-content: space-between;
`;
