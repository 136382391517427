export const allPermissionKeys: string[] = [
  "0",
  "0-1",
  "1",
  "1-1",
  "2",
  "2-1",
  "3",
  "3-1",
  "4",
  "4-1",
  "5",
  "5-1",
  "7",
  "7-1",
];
