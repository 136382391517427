import { Modal } from "antd";
import React, { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { slaSlice } from "../../store/slaSlice";
import { displaySLAType } from "../../utils/displaySLAType";
import { displayStatus } from "../../utils/displayStatus";
import { formatTime } from "../../utils/formatTime";
import { Label, Value, Wrapper } from "../common/displayElement";

export const DisplaySLAModal: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const displaySLA = useAppSelector((state) => state.sla.displaySLA);
  const { list } = useAppSelector((state) => state.channel.channels);

  const { displayModalVisible } = useAppSelector((state) => state.sla.modal);

  const handleOk = () => {
    dispatch(slaSlice.actions.toggleDisplayModal());
  };

  const handleClose = () => {
    dispatch(slaSlice.actions.setDisplaySLA(null));
  };

  return (
    <Modal
      title="SLA 內容"
      visible={displayModalVisible}
      onOk={handleOk}
      onCancel={handleOk}
      cancelButtonProps={{ style: { display: "none" } }}
      okText={"返回"}
      destroyOnClose={true}
      afterClose={handleClose}
    >
      <Wrapper>
        <Label>提醒名稱：</Label>
        <Value>{displaySLA?.name}</Value>
      </Wrapper>
      <Wrapper>
        <Label>狀態：</Label>
        <Value>{displayStatus(displaySLA?.status)}</Value>
      </Wrapper>
      <Wrapper>
        <Label>類型：</Label>
        <Value>{displaySLAType(displaySLA?.type)}</Value>
      </Wrapper>
      <Wrapper>
        <Label>服務提醒交談清單：</Label>
        <Value>
          {displaySLA?.slaChannels
            ?.map((slaChannel) => {
              slaChannel.channelId;
              const channel = list.find((c) => c.id === slaChannel.channelId);
              return channel?.name || "";
            })
            .join(", ")}
        </Value>
      </Wrapper>
      <Wrapper>
        <Label>發送至：</Label>
        <Value>
          {(displaySLA?.sendToChannels as any)
            ?.split(",")
            .map((channelID: string) => {
              const channel = list.find((c) => c.id === channelID);
              return channel?.name || "";
            })
            .join(", ")}
        </Value>
      </Wrapper>
      <Wrapper>
        <Label>更新人員：</Label>
        <Value>{displaySLA?.updatedUsername}</Value>
      </Wrapper>
      <Wrapper>
        <Label>更新時間：</Label>
        <Value>{formatTime(displaySLA?.updatedAt)}</Value>
      </Wrapper>
    </Modal>
  );
};
