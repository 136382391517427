import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { CreateNotificationModal } from "./CreateNotificationModal";
import { DisplayNotificationModal } from "./DisplayNotificationModal";
import { EditNotificationModal } from "./EditNotificationModal";
import { NotificationSearchCriteria } from "./NotificationSearchCriteria";
import { NotificationTable } from "./NotificationTable";
import { useMount } from "react-use";
import { useDispatch } from "react-redux";
import { getEmails } from "../../api/emailAPI";

export const NotificationManagement: FunctionComponent = () => {
  const dispatch = useDispatch();

  useMount(() => {
    dispatch(getEmails(1));
  });

  return (
    <Wrapper>
      <NotificationSearchCriteria />
      <NotificationTable />
      <CreateNotificationModal />
      <EditNotificationModal />
      <DisplayNotificationModal />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
