import {
  AutoComplete,
  Button,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Select,
  Spin,
} from "antd";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useToggle } from "react-use";
import { getActiveMessageTemplates, getMessageTemplates } from "../../api/messageTemplateAPI";
import { refreshNotifications } from "../../api/notificationAPI";
import { AppContext } from "../../core/api/AppContext";
import { useAppSelector } from "../../store/hooks";
import { notificationSlice } from "../../store/notificationSlice";
import { NotificationFields, Resource } from "../../types/Notification";
import { displayStatus } from "../../utils/displayStatus";
import { formatSearchStatus } from "../../utils/formatSearchStatus";
import { Label, Value, Wrapper } from "../common/displayElement";
import { useChannels } from "../../hooks/useChannels";
import _ from "lodash";
import { NotificationType } from "../../constants/NotificationType";
import { useActiveGroups } from "../../hooks/useActiveGroups";

export const EditNotificationModal: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm<NotificationFields>();
  const [initialValues, setInitialValues] = useState<NotificationFields>();
  const headOffice = useAppSelector((state) => state.headOffice);
  const templateList = useAppSelector(
    (state) => state.messageTemplate.activeMessageTemplates
  );
  const [sendType, setSendType] = useState<NotificationType>(
    NotificationType.Chat
  );
  const {
    channels,
    loading: loadingChannels,
    loadingRef: loadingChannelRef,
    hasNextPage: hasNextChannelPage,
    getChannelsByName,
  } = useChannels(true);
  const {
    groups
  } = useActiveGroups();

  const { editModalVisible } = useAppSelector(
    (state) => state.notification.modal
  );
  const editingNotification = useAppSelector(
    (state) => state.notification.editingNotification
  );
  const [loading, setLoading] = useToggle(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [step, setStep] = useState<1 | 2>(1);

  const toggleModal = () => {
    if (editModalVisible) {
      //refetchAll();
    }
    dispatch(notificationSlice.actions.toggleEditModal());
  };

  const editNotification = (send: boolean) => {
    form.validateFields().then((values) => {
      if (
        sendType === NotificationType.Email &&
        _.isEmpty(values["headOffice"])
      ) {
        notification.warn({
          message: "請填寫總社",
          placement: "bottomRight",
        });
        return;
      }

      if (
        sendType === NotificationType.Chat &&
        _.isEmpty(values["channels"]) &&
        _.isEmpty(values["groups"])
      ) {
        notification.warn({
          message: "請填寫交談或分群",
          placement: "bottomRight",
        });
        return;
      }

      setLoading(true);
      setBtnLoading(true);
      const payload = {
        ...values,
        channels: sendType === NotificationType.Chat ? values.channels : [],
        groups: sendType === NotificationType.Chat ? values.groups : [],
      };
      AppContext.ApiExecutor.updateNotification(editingNotification!.id, {
        ...payload,
        send,
      })
        .then(() => {
          toggleModal();
          dispatch(refreshNotifications());
        })
        .finally(() => {
          setLoading(false);
          setBtnLoading(false);
        });
    });
  };

  const onSelectTemplate = (content: string) => {
    form.setFieldsValue({ content });
  };

  const goToNextStep = () => {
    form
      .validateFields()
      .then(() => {
        setStep(2);
      })
      .catch((e) => console.warn(e));
  };

  const goToPrevStep = () => {
    setStep(1);
  };

  const afterClose = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (editModalVisible && editingNotification) {
      const filter = formatSearchStatus(0);
      dispatch(getMessageTemplates(1, filter));
      dispatch(getActiveMessageTemplates());
      setSendType(editingNotification.type);
    } else {
      setStep(1);
    }
  }, [editModalVisible]);

  useEffect(() => {
    if (editingNotification) {
      setInitialValues({
        ...editingNotification,
        headOffice: editingNotification.emailHeadOffice || "",
      });
    } else {
      setInitialValues(undefined);
    }
  }, [editingNotification]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues]);

  return (
    <Modal
      title="編輯通知"
      visible={editModalVisible}
      onCancel={toggleModal}
      confirmLoading={loading}
      destroyOnClose={true}
      afterClose={afterClose}
      footer={[
        step === 1 ? (
          <Button onClick={toggleModal}>取消</Button>
        ) : (
          <Button onClick={goToPrevStep}>上一步</Button>
        ),
        step === 1 ? (
          <Button type={"primary"} onClick={goToNextStep}>
            下一步
          </Button>
        ) : (
          <>
            <Button type={"primary"} onClick={() => editNotification(false)}>
              儲存
            </Button>
            <Button type={"primary"} onClick={() => editNotification(true)} loading={btnLoading}>
              發送
            </Button>
          </>
        ),
      ]}
    >
      {editModalVisible && (
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={initialValues}
          form={form}
        >
          <div style={{ display: step === 1 ? "" : "none" }}>
            <Form.Item
              label="通知名稱"
              name="name"
              rules={[{ required: true, message: "請輸入通知名稱" }]}
            >
              <Input placeholder={"通知名稱"} />
            </Form.Item>
            <Form.Item label="說明" name="description">
              <Input placeholder={"說明"} />
            </Form.Item>
            <Form.Item label="狀態" name="status" rules={[{ required: true }]}>
              <Radio.Group>
                <Radio value={0}>啟用</Radio>
                <Radio value={1}>停用</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="範本">
              <Select placeholder="請選擇範本" onSelect={onSelectTemplate}>
                {templateList.map((template) => (
                  <Select.Option key={template.id} value={template.content}>
                    {template.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="類型" name="type">
              <Radio.Group
                onChange={(e) => setSendType(e.target.value)}
                value={sendType}
              >
                <Radio value={NotificationType.Chat}>交談</Radio>
                <Radio value={NotificationType.Email}>Email</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="通知訊息"
              name="content"
              rules={[{ required: true, message: "請輸入通知訊息" }]}
            >
              <Input.TextArea rows={4} placeholder={"通知訊息"} />
            </Form.Item>
            {sendType === NotificationType.Chat && (
              <Form.Item label="發送分群" name="groups">
                <Select mode={"multiple"} filterOption={false}>
                {
                  ((form.getFieldValue("sendToGroups") || []) as Resource[]).map((selectedGroup : Resource) => {
                    const group = groups.filter((g) => g.id === selectedGroup.id);
                    if (group.length === 0) {
                      return (
                        <Select.Option key={selectedGroup.id} value={selectedGroup.id}>
                          {selectedGroup.name}
                        </Select.Option>
                      );
                    }
                })}
                {
                  groups.map((group) => (
                    <Select.Option key={group.id} value={group.id}>
                      {group.name}
                    </Select.Option>
                  ))                  
                }
                </Select>
              </Form.Item>
            )}
            {sendType === NotificationType.Chat && (
              <Form.Item label="交談清單" name="channels">
                <Select
                  mode={"multiple"}
                  filterOption={false}
                  onSearch={(text) => getChannelsByName(text)}
                  onDropdownVisibleChange={(dropdownVisible) => {
                    if (!dropdownVisible) {
                      getChannelsByName("");
                    }
                  }}
                >
                  {channels.map((channel) => (
                    <Select.Option key={channel.id} value={channel.id}>
                      {channel.name}
                    </Select.Option>
                  ))}
                  {(loadingChannels || hasNextChannelPage) && (
                    <Select.Option value={"ref"}>
                      <div ref={loadingChannelRef}>
                        <Spin />
                      </div>
                    </Select.Option>
                  )}
                </Select>
              </Form.Item>
            )}
            {sendType === NotificationType.Email && (
              <Form.Item
                label="總社"
                name="headOffice"
                rules={[{ required: true, message: "請輸入總社" }]}
              >
                <AutoComplete placeholder="請輸入總社" style={{ width: 200 }}>
                  {headOffice.emails.map((office) => (
                    <AutoComplete.Option value={office} key={office}>
                      {office}
                    </AutoComplete.Option>
                  ))}
                </AutoComplete>
              </Form.Item>
            )}
          </div>
          <div style={{ display: step === 2 ? "" : "none" }}>
            <Wrapper>
              <Label>通知名稱：</Label>
              <Value>{form.getFieldValue("name")}</Value>
            </Wrapper>
            <Wrapper>
              <Label>說明：</Label>
              <Value>{form.getFieldValue("description")}</Value>
            </Wrapper>
            <Wrapper>
              <Label>狀態：</Label>
              <Value>{displayStatus(form.getFieldValue("status"))}</Value>
            </Wrapper>
            <Wrapper>
              <Label>通知訊息：</Label>
              <Value>{form.getFieldValue("content")}</Value>
            </Wrapper>
            {sendType === NotificationType.Chat && (
              <Wrapper>
                <Label>交談清單：</Label>
                <Value>
                  {
                    channels.find((channel) => {
                      const channelIDs = form.getFieldValue("channels") || [];
                      return channelIDs.includes(channel.id);
                    })?.name
                  }
                </Value>
              </Wrapper>
            )}
            {sendType === NotificationType.Chat && (
              <Wrapper>
                <Label>分群清單：</Label>
                <Value>
                  {(() => {
                    const groupIDs = form.getFieldValue("groups") || [];
                    const filteredGroupNames = groups.filter((group) => groupIDs.includes(group.id))
                    .map((g) => g.name).join(", ");
                    return filteredGroupNames;
                  })()}
                </Value>
              </Wrapper>
            )}
            {sendType === NotificationType.Email && (
              <Wrapper>
                <Label>總社：</Label>
                <Value>{form.getFieldValue("headOffice")}</Value>
              </Wrapper>
            )}
          </div>
        </Form>
      )}
    </Modal>
  );
};
