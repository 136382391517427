import { EditOutlined, SendOutlined } from "@ant-design/icons";
import { Input, Modal, notification as uiNotification, Table } from "antd";
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import React, { FunctionComponent, useState } from "react";
import { useDispatch } from "react-redux";
import { useToggle } from "react-use";
import {
  getNotifications,
  refreshNotifications,
} from "../../api/notificationAPI";
import { Function } from "../../constants/Function";
import { pageSize } from "../../constants/pagination";
import { Permission } from "../../constants/Permission";
import { AppContext } from "../../core/api/AppContext";
import { usePermission } from "../../hooks/usePermissions";
import { useAppSelector } from "../../store/hooks";
import { notificationSlice } from "../../store/notificationSlice";
import { Notification, Resource } from "../../types/Notification";
import { displayStatus } from "../../utils/displayStatus";
import { formatTime } from "../../utils/formatTime";
import { getOrderBy } from "../../utils/getOrderBy";
import { showTotalData } from "../../utils/showTotalData";
import { ResourceName } from "../common/ResourceName";
import { TableActionWrapper } from "../common/TableActionWrapper";
import _ from "lodash";
import { NotificationType } from "../../constants/NotificationType";

export const NotificationTable: FunctionComponent = () => {
  const notificationManagementPermission = usePermission(Function.Notification);
  const dispatch = useDispatch();
  const [displaySend, setDisplaySend] = useToggle(false);
  const [sendInput, setSendInput] = useState("");
  const { notifications, searchParams } = useAppSelector(
    (state) => state.notification
  );
  const [willSendNotify, setWillSendNotify] = useState<Notification>();

  const handleEdit = (notification: Notification): void => {
    dispatch(
      notificationSlice.actions.setEditingNotification({
        ...notification,
        channels: notification.sendToChannels.map((channel) => channel.id),
        groups: notification.sendToGroups.map((group) => group.id),
      })
    );
    dispatch(notificationSlice.actions.toggleEditModal());
  };

  const toggleSend = (notification: Notification): void => {
    if (
      notification.type === NotificationType.Email &&
      _.isEmpty(notification.emailHeadOffice)
    ) {
      uiNotification.warning({
        message: "寄送失敗，請輸入總社。",
        placement: "bottomRight",
      });
      return;
    }
    if (
      notification.type === NotificationType.Chat &&
      _.isEmpty(notification.sendToChannels) &&
      _.isEmpty(notification.sendToGroups)
    ) {
      uiNotification.warning({
        message: "寄送失敗，請填寫交談或分群。",
        placement: "bottomRight",
      });
      return;
    }
    setWillSendNotify(notification);
    setDisplaySend(true);
  };

  const handleSend = (): void => {
    if (sendInput !== "Yes") {
      uiNotification.warn({ message: "確認送出請輸入 Yes", placement: "top" });
      return;
    }
    if (willSendNotify) {
      AppContext.ApiExecutor.sendNotification(willSendNotify.id)
        .then(() => {
          dispatch(refreshNotifications());
          uiNotification.success({
            message: "寄送成功",
            placement: "bottomRight",
          });
        })
        .catch(() => {
          uiNotification.warning({
            message: "寄送失敗",
            placement: "bottomRight",
          });
        })
        .finally(() => {
          setDisplaySend(false);
        });
    }
  };

  const handleDisplay = (notification: Notification): void => {
    dispatch(
      notificationSlice.actions.setDisplayNotification({
        ...notification,
        channels: notification.sendToChannels.map((channel) => channel.id),
        groups: notification.sendToGroups.map((group) => group.id),
      })
    );
    dispatch(notificationSlice.actions.toggleDisplayModal());
  };

  const onFilterChange = (
    config: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<Notification> | SorterResult<Notification>[]
  ) => {
    const orderBy = getOrderBy<Notification>(sorter);
    dispatch(
      getNotifications(config.current ?? 1, searchParams.filter, orderBy)
    );
  };

  return (
    <>
      <Table<Notification>
        tableLayout={"auto"}
        loading={notifications.fetching}
        dataSource={notifications.list}
        scroll={{ y: 800, x: 700 }}
        onChange={onFilterChange}
        pagination={{
          pageSize,
          showSizeChanger: false,
          total: notifications.total,
          current: notifications.page,
          showTotal: () => showTotalData(notifications.total),
        }}
        rowKey={"id"}
      >
        <Table.Column<Notification>
          title="通知名稱"
          dataIndex="name"
          sorter={true}
          width={150}
          render={(name: string, data) => (
            <ResourceName onClick={() => handleDisplay(data)}>
              {name}
            </ResourceName>
          )}
        />
        <Table.Column
          title="說明"
          dataIndex="description"
          width={150}
        />
        <Table.Column
          title="分群名稱"
          dataIndex="sendToGroups"
          align={"center"}
          width={300}
          render={(e) => e?.map((group: Resource) => group.name).join(", ")}
        />
        <Table.Column
          sorter={true}
          title="更新人員"
          dataIndex="updatedUsername"
          width={150}
        />
        <Table.Column
          sorter={true}
          title="更新時間"
          dataIndex="updatedAt"
          width={200}
          align={"center"}
          render={(e: string) => formatTime(e)}
        />
        <Table.Column
          sorter={true}
          title="最後發送時間"
          dataIndex="sentAt"
          align={"center"}
          width={100}
          render={(e: string) => formatTime(e)}
        />
        <Table.Column<Notification>
          sorter={true}
          title="狀態"
          dataIndex="status"
          align={"center"}
          width={100}
          render={(status: number) => displayStatus(status)}
        />
        <Table.Column<Notification>
          title="操作"
          dataIndex="id"
          align={"center"}
          width={100}
          render={(id, data) => {
            return (
              <TableActionWrapper>
                {notificationManagementPermission.includes(Permission.Edit) && (
                  <EditOutlined onClick={() => handleEdit(data)} />
                )}
                {notificationManagementPermission.some(
                  (permission) => permission > Permission.View
                ) &&
                  data.status === 0 && (
                    <SendOutlined onClick={() => toggleSend(data)} />
                  )}
              </TableActionWrapper>
            );
          }}
        />
      </Table>
      <Modal
        visible={displaySend}
        title="確認發送通知？"
        onCancel={() => {
          setDisplaySend(false);
        }}
        destroyOnClose={true}
        onOk={handleSend}
        afterClose={() => {
          setSendInput("");
          setWillSendNotify(undefined);
        }}
      >
        <div style={{ marginBottom: 10 }}>
          {`確認發送通知「${willSendNotify?.name}」，請輸入 Yes`}
        </div>
        <div style={{ marginBottom: 10 }}>
          發送目標：
          {[
            ...(willSendNotify?.sendToGroups || []),
            ...(willSendNotify?.sendToChannels || []),
          ]
            .map((resource) => resource.name)
            .join(", ")}
        </div>
        <Input
          placeholder={"Yes"}
          value={sendInput}
          onChange={(e) => setSendInput(e.target.value)}
        />
      </Modal>
    </>
  );
};
