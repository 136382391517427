import { ApiExecutor } from "./ApiExecutor";
import { config } from "../config";

let apiExecutor: any;

export class AppContext {
  public static get ApiExecutor(): ApiExecutor {
    if (!apiExecutor) {
      this.buildApiExecutor();
    }

    return apiExecutor;
  }

  public static buildApiExecutor(): void {
    apiExecutor = new ApiExecutor({
      baseURL: config.apiURL,
      headers: {
        ["Accept"]: "application/json",
        ["Content-Type"]: "application/json",
        ["Access-Control-Allow-Origin"]: "*",
      },
    });
  }
}
