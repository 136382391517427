import { Modal } from "antd";
import React, { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { displayStatus } from "../../utils/displayStatus";
import { Label, Value, Wrapper } from "../common/displayElement";
import { emailSlice } from "../../store/emailSlice";

export const DisplayEmailModal: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const displayEmail = useAppSelector((state) => state.email.displayEmail);

  const { displayModalVisible } = useAppSelector((state) => state.email.modal);

  const handleOk = () => {
    dispatch(emailSlice.actions.toggleDisplayModal());
  };

  const handleClose = () => {
    dispatch(emailSlice.actions.setDisplayEmail(null));
  };
  return (
    <Modal
      title="Email"
      visible={displayModalVisible}
      onOk={handleOk}
      onCancel={handleOk}
      cancelButtonProps={{ style: { display: "none" } }}
      okText={"返回"}
      destroyOnClose={true}
      afterClose={handleClose}
    >
      <Wrapper>
        <Label>Email：</Label>
        <Value>{displayEmail?.address}</Value>
      </Wrapper>
      <Wrapper>
        <Label>說明：</Label>
        <Value>{displayEmail?.description}</Value>
      </Wrapper>
      <Wrapper>
        <Label>狀態：</Label>
        <Value>{displayStatus(displayEmail?.status)}</Value>
      </Wrapper>
      <Wrapper>
        <Label>總社：</Label>
        <Value>{displayEmail?.headOffice}</Value>
      </Wrapper>
      <Wrapper>
        <Label>總社層級：</Label>
        <Value>{displayEmail?.tier}</Value>
      </Wrapper>
    </Modal>
  );
};
