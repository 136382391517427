import { Function } from "../constants/Function";
import { Permission } from "../constants/Permission";
import { FunctionPermission } from "../types/Auth";

export const convertKeysToPermissions = (
  ks: string[]
): FunctionPermission[] => {
  return ks
    .map((key) => key.split("-"))
    .filter((data) => data.length === 2)
    .map((data) => {
      const func: Function = Number(data[0]) as unknown as Function;
      const permission: Permission = Number(data[1]) as unknown as Permission;
      return { function: func, permission };
    });
};
