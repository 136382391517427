import { Form, Input, Modal, Radio } from "antd";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useToggle } from "react-use";
import { refreshMessageTemplates } from "../../api/messageTemplateAPI";
import { AppContext } from "../../core/api/AppContext";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { messageTemplateSlice } from "../../store/messageTemplateSlice";
import { MessageTemplateFields } from "../../types/MessageTemplate";

export const EditTemplateModal: FunctionComponent = () => {
  const [form] = Form.useForm<MessageTemplateFields>();
  const [loading, setLoading] = useToggle(false);
  const [initialValues, setInitialValues] = useState<MessageTemplateFields>();
  const dispatch = useAppDispatch();
  const editingTemplate = useAppSelector(
    (state) => state.messageTemplate.editingTemplate
  );

  const { editModalVisible } = useAppSelector(
    (state) => state.messageTemplate.modal
  );

  const handleOk = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      AppContext.ApiExecutor.updateMessageTemplate(editingTemplate!.id, values)
        .then(() => {
          dispatch(messageTemplateSlice.actions.toggleEditModal());
          dispatch(refreshMessageTemplates());
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const handleCancel = () => {
    dispatch(messageTemplateSlice.actions.toggleEditModal());
  };

  const handleClose = () => {
    dispatch(messageTemplateSlice.actions.setEditingTemplate(null));
  };

  useEffect(() => {
    setInitialValues(editingTemplate || undefined);
  }, [editingTemplate]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues]);

  return (
    <Modal
      title="編輯範本"
      visible={editModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"儲存"}
      cancelText={"取消"}
      destroyOnClose={true}
      afterClose={handleClose}
      confirmLoading={loading}
    >
      {editModalVisible && (
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={initialValues}
          form={form}
        >
          <Form.Item label="狀態" name="status" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={0}>啟用</Radio>
              <Radio value={1}>停用</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="範本名稱"
            name="name"
            rules={[{ required: true, message: "請輸入範本名稱" }]}
          >
            <Input placeholder={"範本名稱"} />
          </Form.Item>
          <Form.Item
            label="內容"
            name="content"
            rules={[{ required: true, message: "請輸入內容" }]}
          >
            <Input.TextArea rows={6} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
