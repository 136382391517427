import _ from "lodash";
import { Role } from "../constants/Role";

export const displayRole = (role: Role = Role.User): string => {
  const roleMap: Record<Role, string> = {
    [Role.SystemAdmin]: "系統管理員",
    [Role.Admin]: "管理員",
    [Role.User]: "一般使用者",
  };

  return _.get(roleMap, role, "");
};
