import _ from "lodash";
import { SLAType } from "../constants/SLAType";

export const displaySLAType = (slaType: SLAType = SLAType.Bot): string => {
  const slaTypeMap: Record<SLAType, string> = {
    [SLAType.Bot]: "Chat",
    [SLAType.Jira]: "Jira",
  };

  return _.get(slaTypeMap, slaType, "");
};
