import styled from "styled-components";

export const ResourceName = styled.div`
  cursor: pointer;
  text-decoration: underline;
  color: #1890ff;

  &:hover {
    color: #40a9ff;
  }
`;
