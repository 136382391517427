import { Button, Form, Input } from "antd";
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { useMount } from "react-use";
import styled from "styled-components";
import { getEmails } from "../../api/emailAPI";
import { emailSlice } from "../../store/emailSlice";
import { formatSearchFilter } from "../../utils/formatSearchFilter";
import { formatFuzzySearch } from "../../utils/formatFuzzySearch";
import { usePermission } from "../../hooks/usePermissions";
import { Function } from "../../constants/Function";
import { Permission } from "../../constants/Permission";
import { getEmailsHeadOffices } from "../../api/headOfficeAPI";

export const EmailSearchCriteria: FunctionComponent = () => {
  const emailManagementPermission = usePermission(Function.Email);
  const [form] = Form.useForm<{
    searchText?: string;
  }>();
  const dispatch = useDispatch();

  const handleCreateEmail = () => {
    dispatch(emailSlice.actions.toggleCreateModal());
  };

  const fetch = () => {
    const values = form.getFieldsValue();
    const filter = formatSearchFilter([
      formatFuzzySearch(["address"], values.searchText),
    ]);

    dispatch(getEmails(1, filter));
  };

  useMount(() => {
    fetch();
    dispatch(getEmailsHeadOffices());
  });

  return (
    <ActionWrapper>
      <Form form={form} layout={"inline"}>
        <Form.Item label="Email" name="searchText">
          <Input
            allowClear
            placeholder={"請輸入Email"}
            style={{ width: 200 }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={fetch} htmlType="submit">
            搜尋
          </Button>
        </Form.Item>
      </Form>
      {emailManagementPermission.includes(Permission.Add) && (
        <Button type={"primary"} onClick={handleCreateEmail}>
          新增 Email
        </Button>
      )}
    </ActionWrapper>
  );
};

const ActionWrapper = styled.div`
  margin: 0 0 24px 0;
  display: flex;
  justify-content: space-between;
`;
