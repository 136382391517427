import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { ApiConfig } from "./ApiConfig";
import { tokenProvider } from "./tokenProvider";

export class ApiCaller {
  private readonly axiosInstance: AxiosInstance;

  constructor(private readonly config: ApiConfig) {
    const { baseURL, headers } = config;
    this.axiosInstance = axios.create({
      baseURL,
      headers,
    });
    this.setUpInterceptors();
  }

  public get axios(): AxiosInstance {
    return this.axiosInstance;
  }
  private setUpInterceptors(): void {
    this.axiosInstance.interceptors.request.use(
      async (requestConfig: AxiosRequestConfig) => {
        try {
          const token = tokenProvider();

          if (token && requestConfig.headers) {
            requestConfig.headers.Authorization = token;
          }
        } catch (e) {
          // ignoring when token failed to retrieve token.
        }

        return requestConfig;
      }
    );
  }
}
