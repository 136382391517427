import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { CreateUserModal } from "./CreateUserModal";
import { DisplayUserModal } from "./DisplayUserModal";
import { EditUserModal } from "./EditUserModal";
import { UserSearchCriteria } from "./UserSearchCriteria";
import { UserTable } from "./UserTable";

export const UserManagement: FunctionComponent = () => {
  return (
    <Wrapper>
      <UserSearchCriteria />
      <UserTable />
      <CreateUserModal />
      <EditUserModal />
      <DisplayUserModal />
    </Wrapper>
  );
};

const Wrapper = styled.div``;
