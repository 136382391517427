import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchParams } from "../types/api";
import { Channel, ChannelState, GetChannelsResponse } from "../types/Channel";

const initialState: ChannelState = {
  modal: {
    createModalVisible: false,
    editModalVisible: false,
    displayModalVisible: false,
  },
  channels: {
    list: [],
    fetching: false,
    total: 0,
    totalPages: 0,
    page: 0,
  },
  editingChannel: null,
  displayChannel: null,
  searchParams: {
    page: 1,
    filter: "",
    orderBy: "",
  },
};

export const channelSlice = createSlice({
  name: "channel",
  initialState,
  reducers: {
    fetchingChannels: (state) => {
      state.channels.fetching = true;
    },
    fetchChannelsSuccess: (
      state,
      action: PayloadAction<GetChannelsResponse>
    ) => {
      state.channels.fetching = false;
      state.channels.list = action.payload.channels;
      state.channels.total = action.payload.count;
      state.channels.totalPages = action.payload.totalPages;
    },
    fetchChannelsFail: (state) => {
      state.channels.fetching = false;
      state.channels.list = [];
    },
    setEditingChannel: (state, action: PayloadAction<Nullable<Channel>>) => {
      state.editingChannel = action.payload;
    },
    setDisplayChannel: (state, action: PayloadAction<Nullable<Channel>>) => {
      state.displayChannel = action.payload;
    },
    toggleCreateModal: (state) => {
      state.modal.createModalVisible = !state.modal.createModalVisible;
    },
    toggleEditModal: (state) => {
      state.modal.editModalVisible = !state.modal.editModalVisible;
    },
    toggleDisplayModal: (state) => {
      state.modal.displayModalVisible = !state.modal.displayModalVisible;
    },
    changeCurrentPage: (state, action: PayloadAction<number>) => {
      state.channels.page = action.payload;
    },
    setSearchParams: (state, action: PayloadAction<SearchParams>) => {
      state.searchParams = action.payload;
    },
  },
});
