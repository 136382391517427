import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchParams } from "../types/api";
import {
  GetNotificationsResponse,
  Notification,
  NotificationState
} from "../types/Notification";

const initialState: NotificationState = {
  modal: {
    createModalVisible: false,
    editModalVisible: false,
    displayModalVisible: false
  },
  notifications: {
    list: [],
    fetching: false,
    total: 0,
    totalPages: 0,
    page: 0
  },
  editingNotification: null,
  displayNotification: null,
  searchParams: {
    page: 1,
    filter: "",
    orderBy: ""
  }
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    fetchingNotifications: (state) => {
      state.notifications.fetching = true;
    },
    fetchNotificationsSuccess: (
      state,
      action: PayloadAction<GetNotificationsResponse>
    ) => {
      state.notifications.fetching = false;
      state.notifications.list = action.payload.notifications;
      state.notifications.total = action.payload.count;
      state.notifications.totalPages = action.payload.totalPages;
    },
    fetchNotificationsFail: (state) => {
      state.notifications.fetching = false;
      state.notifications.list = [];
    },
    setEditingNotification: (
      state,
      action: PayloadAction<Nullable<Notification>>
    ) => {
      state.editingNotification = action.payload;
    },
    setDisplayNotification: (
      state,
      action: PayloadAction<Nullable<Notification>>
    ) => {
      state.displayNotification = action.payload;
    },
    toggleCreateModal: (state) => {
      state.modal.createModalVisible = !state.modal.createModalVisible;
    },
    toggleEditModal: (state) => {
      state.modal.editModalVisible = !state.modal.editModalVisible;
    },
    toggleDisplayModal: (state) => {
      state.modal.displayModalVisible = !state.modal.displayModalVisible;
    },
    changeCurrentPage: (state, action: PayloadAction<number>) => {
      state.notifications.page = action.payload;
    },
    setSearchParams: (state, action: PayloadAction<SearchParams>) => {
      state.searchParams = action.payload;
    }
  }
});
