import {
  EditChannelFields,
  GetChannelsRequest,
  GetChannelsResponse,
} from "../../types/Channel";
import {
  CreateGroupPayload,
  EditGroupPayload,
  GetGroupsRequest,
  GetGroupsResponse,
  GetGroupResponse,
  Group,
} from "../../types/Group";
import { HeadOfficeState } from "../../types/HeadOffice";
import {
  GetJiraReportsRequest,
  GetJiraReportsResponse,
} from "../../types/JiraReport";
import {
  CreateMessageTemplateResponse,
  GetMessageTemplatesRequest,
  GetMessageTemplatesResponse,
  GetActiveMessageTemplatesResponse,
  MessageTemplateFields,
} from "../../types/MessageTemplate";
import {
  Notification,
  CreateNotificationResponse,
  GetNotificationsRequest,
  GetNotificationsResponse,
  NotificationFields,
} from "../../types/Notification";
import {
  GetNotificationReportsRequest,
  GetNotificationReportsResponse,
} from "../../types/NotificationReport";
import {
  CreateSLAResponse,
  GetSLAsRequest,
  GetSLAsResponse,
  SLAFields,
} from "../../types/SLA";
import {
  GetSLAReportsRequest,
  GetSLAReportsResponse,
} from "../../types/SLAReport";
import {
  CreateUserResponse,
  GetUsersRequest,
  GetUsersResponse,
  UserFields,
} from "../../types/User";
import { ApiCaller } from "./ApiCaller";
import { ApiConfig } from "./ApiConfig";
import {
  CreateEmailPayload,
  GetEmailsRequest,
  GetEmailsResponse,
} from "../../types/Email";

export class ApiExecutor {
  private readonly config: ApiConfig;
  private readonly caller: ApiCaller;

  constructor(config: ApiConfig) {
    this.config = config;
    this.caller = new ApiCaller(config);
  }

  // Auth

  public getRolePermission() {
    return this.caller.axios.get("/auth/rolepermissions");
  }

  // User

  public getUsers(params: GetUsersRequest) {
    return this.caller.axios.get<GetUsersResponse>("/user", {
      params,
    });
  }

  public createUser(user: UserFields) {
    return this.caller.axios.post<CreateUserResponse>("/user", user);
  }

  public updateUser(id: string, user: UserFields) {
    return this.caller.axios.put(`/user/${id}`, user);
  }

  public deleteUser(id: string) {
    return this.caller.axios.delete(`/user/${id}`);
  }

  // MessageTemplate

  public getMessageTemplates(params: GetMessageTemplatesRequest) {
    return this.caller.axios.get<GetMessageTemplatesResponse>(
      "/messageTemplate",
      {
        params,
      }
    );
  }

  public getActiveMessageTemplates() {
    return this.caller.axios.get<GetActiveMessageTemplatesResponse[]>("/messageTemplate/active");
  }

  public createMessageTemplate(messageTemplate: MessageTemplateFields) {
    return this.caller.axios.post<CreateMessageTemplateResponse>(
      "/messageTemplate",
      messageTemplate
    );
  }

  public updateMessageTemplate(
    id: string,
    messageTemplate: MessageTemplateFields
  ) {
    return this.caller.axios.put(`/messageTemplate/${id}`, messageTemplate);
  }

  public deleteMessageTemplate(id: string) {
    return this.caller.axios.delete(`/messageTemplate/${id}`);
  }

  // Email

  public getEmails(params: GetEmailsRequest) {
    return this.caller.axios.get<GetEmailsResponse>("/email", {
      params: {
        ...params,
      },
    });
  }

  public createEmail(email: CreateEmailPayload) {
    return this.caller.axios.post("/email", email);
  }

  public updateEmail(id: string, email: CreateEmailPayload) {
    return this.caller.axios.put(`/email/${id}`, email);
  }

  public deleteEmail(id: string) {
    return this.caller.axios.delete(`/email/${id}`);
  }

  // Notification

  public getNotification(id: string) {
    return this.caller.axios.get<Notification>(`/notification/${id}`);
  }

  public getNotifications(params: GetNotificationsRequest) {
    return this.caller.axios.get<GetNotificationsResponse>("/notification", {
      params: {
        ...params,
        groupname: "",
      },
    });
  }

  public createNotification(notification: NotificationFields) {
    return this.caller.axios.post<CreateNotificationResponse>(
      "/notification",
      notification
    );
  }

  public sendNotification(id: string) {
    return this.caller.axios.post<CreateNotificationResponse>(
      `/notification/${id}`
    );
  }

  public updateNotification(id: string, notification: NotificationFields) {
    return this.caller.axios.put(`/notification/${id}`, notification);
  }

  // Channel

  public getChannels(params: GetChannelsRequest) {
    return this.caller.axios.get<GetChannelsResponse>("/channel", {
      params,
    });
  }

  public updateChannel(id: string, channel: EditChannelFields) {
    return this.caller.axios.put(`/channel/${id}`, channel);
  }

  // Group

  public getGroup(id: string) {
    return this.caller.axios.get<Group>(`/group/${id}`);
  }

  public getGroups(params: GetGroupsRequest) {
    return this.caller.axios.get<GetGroupsResponse>("/group", {
      params,
    });
  }

  public getActiveGroups() {
    return this.caller.axios.get<GetGroupResponse[]>("/group/active");
  }

  public getAllGroups() {
    return this.caller.axios.get<GetGroupResponse[]>("/group/all");
  }

  public createGroup(group: CreateGroupPayload) {
    return this.caller.axios.post("/group", group);
  }

  public updateGroup(id: string, group: EditGroupPayload) {
    return this.caller.axios.put(`/group/${id}`, group);
  }

  // Head Office

  public getOffices() {
    return this.caller.axios.get<HeadOfficeState["list"]>(
      "/channel/headoffice"
    );
  }

  public getEmailsOffices() {
    return this.caller.axios.get<HeadOfficeState["emails"]>(
      "/email/headoffice"
    );
  }

  // SLA

  public getSLAs(params: GetSLAsRequest) {
    return this.caller.axios.get<GetSLAsResponse>("/sla", {
      params,
    });
  }

  public createSLA(sla: SLAFields) {
    return this.caller.axios.post<CreateSLAResponse>("/sla", sla);
  }

  public updateSLA(id: string, sla: SLAFields) {
    return this.caller.axios.put(`/sla/${id}`, sla);
  }

  // Report

  public getNotificationReports(params: GetNotificationReportsRequest) {
    return this.caller.axios.get<GetNotificationReportsResponse>(
      "/notificationreport",
      {
        params: {
          ...params,
        },
      }
    );
  }

  public resendNotificationReport(id: string) {
    return this.caller.axios.post(`/notificationreport/resend?id=${id}`);
  }

  public getSLAReports(params: GetSLAReportsRequest) {
    return this.caller.axios.get<GetSLAReportsResponse>("/slareport", {
      params: {
        ...params,
      },
    });
  }

  public getJiraReports(params: GetJiraReportsRequest) {
    return this.caller.axios.get<GetJiraReportsResponse>("/jirareport", {
      params: {
        ...params,
      },
    });
  }

  public downloadSLAReports(params: GetSLAReportsRequest) {
    return this.caller.axios.get<string>("/slareport/csv", {
      params: {
        ...params,
      },
    });
  }

  // snooze

  public sentJiraReportNotification(channelId: string, url: string) {
    return this.caller.axios.post(
      `/jirareport/sendnotification?channelId=${channelId}&url=${url}`
    );
  }
}
