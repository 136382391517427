import { AutoComplete, Form, Input, Modal, Radio } from "antd";
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { useToggle } from "react-use";
import { AppContext } from "../../core/api/AppContext";
import { emailSlice } from "../../store/emailSlice";
import { useAppSelector } from "../../store/hooks";
import { EmailFields } from "../../types/Email";
import { refreshEmails } from "../../api/emailAPI";

export const CreateEmailModal: FunctionComponent = () => {
  const [form] = Form.useForm<EmailFields>();
  const [loading, setLoading] = useToggle(false);
  const dispatch = useDispatch();
  const { createModalVisible } = useAppSelector((state) => state.email.modal);
  const headOffice = useAppSelector((state) => state.headOffice);

  const handleOk = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      AppContext.ApiExecutor.createEmail(values)
        .then(() => {
          dispatch(emailSlice.actions.toggleCreateModal());
          dispatch(refreshEmails());
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const handleCancel = () => {
    dispatch(emailSlice.actions.toggleCreateModal());
  };

  const handleClose = () => {
    form.resetFields();
  };

  return (
    <Modal
      title="新增 Email"
      visible={createModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"儲存"}
      confirmLoading={loading}
      cancelText={"取消"}
      destroyOnClose={true}
      afterClose={handleClose}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ status: 0 }}
        form={form}
      >
        <Form.Item
          label="Email"
          name="address"
          rules={[{ type: "email", required: true, message: "請輸入 Email" }]}
        >
          <Input placeholder={"example@domain.com"} />
        </Form.Item>
        <Form.Item
          label="總社"
          name="headOffice"
          rules={[{ required: true, message: "請輸入總社" }]}
        >
          <AutoComplete placeholder="請輸入總社" style={{ width: 200 }}>
            {headOffice.emails.map((office) => (
              <AutoComplete.Option value={office} key={office}>
                {office}
              </AutoComplete.Option>
            ))}
          </AutoComplete>
        </Form.Item>
        <Form.Item label="說明" name="description">
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item label="狀態" name="status" rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value={0}>啟用</Radio>
            <Radio value={1}>停用</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};
