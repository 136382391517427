import { Form, Input, InputNumber, Modal, Radio, Select } from "antd";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useToggle } from "react-use";
import { refreshSLAs } from "../../api/slaAPI";
import { SLAType } from "../../constants/SLAType";
import { AppContext } from "../../core/api/AppContext";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { slaSlice } from "../../store/slaSlice";
import { displaySLAType } from "../../utils/displaySLAType";

export const EditSLAModal: FunctionComponent = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useToggle(false);
  const [initialValues, setInitialValues] = useState<any>();
  const dispatch = useAppDispatch();
  const editingSLA = useAppSelector((state) => state.sla.editingSLA);
  const channels = useAppSelector((state) => state.channel.channels);

  const { editModalVisible } = useAppSelector((state) => state.sla.modal);

  const handleOk = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      AppContext.ApiExecutor.updateSLA(editingSLA!.id, {
        ...values,
        filterUserIds: values.filterUserIds?.split(",") || [],
      })
        .then(() => {
          dispatch(slaSlice.actions.toggleEditModal());
          dispatch(refreshSLAs());
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const handleCancel = () => {
    dispatch(slaSlice.actions.toggleEditModal());
  };

  const handleClose = () => {
    dispatch(slaSlice.actions.setEditingSLA(null));
  };

  useEffect(() => {
    setInitialValues(editingSLA || undefined);
  }, [editingSLA]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues]);

  return (
    <Modal
      title="編輯 SLA"
      visible={editModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"儲存"}
      cancelText={"取消"}
      destroyOnClose={true}
      afterClose={handleClose}
      confirmLoading={loading}
    >
      {editModalVisible && (
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={initialValues}
          form={form}
        >
          <Form.Item
            label="提醒名稱"
            name="name"
            rules={[{ required: true, message: "請輸入提醒名稱" }]}
          >
            <Input placeholder={"提醒名稱"} disabled />
          </Form.Item>
          <Form.Item label="狀態" name="status" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={0}>啟用</Radio>
              <Radio value={1}>停用</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="SLA 類型" name="type" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={SLAType.Bot}>{displaySLAType(SLAType.Bot)}</Radio>
              <Radio value={SLAType.Jira}>{displaySLAType(SLAType.Jira)}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="過濾清單" name="filterUserIds">
            <Input placeholder={"skype1,skype2"} />
          </Form.Item>
          <Form.Item label="服務提醒交談清單" name="channelIds">
            <Select allowClear style={{ width: 200 }} mode={"multiple"}>
              {channels.list.map((channel) => (
                <Select.Option key={channel.id} value={channel.id}>
                  {channel.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="提醒時間間隔"
            name="intervalMinutes"
            rules={[{ required: true }]}
          >
            <InputNumber addonAfter={"mins"} />
          </Form.Item>
          <Form.Item label="稍後提醒" name="snoozeEnabled">
            <Radio.Group>
              <Radio value={true}>啟用</Radio>
              <Radio value={false}>停用</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="發送至" name="sendToChannels">
            <Select allowClear style={{ width: 200 }} mode={"multiple"}>
              {channels.list.map((channel) => (
                <Select.Option key={channel.id} value={channel.id}>
                  {channel.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
