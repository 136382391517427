import { Modal, notification, Table } from "antd";
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { pageSize } from "../../constants/pagination";
import { useAppSelector } from "../../store/hooks";
import { displayStatus } from "../../utils/displayStatus";
import { formatTime } from "../../utils/formatTime";
import { getOrderBy } from "../../utils/getOrderBy";
import { showTotalData } from "../../utils/showTotalData";
import { ResourceName } from "../common/ResourceName";
import { getEmails, refreshEmails } from "../../api/emailAPI";
import { Email } from "../../types/Email";
import { emailSlice } from "../../store/emailSlice";
import { TableActionWrapper } from "../common/TableActionWrapper";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { usePermission } from "../../hooks/usePermissions";
import { Function } from "../../constants/Function";
import { Permission } from "../../constants/Permission";
import { AppContext } from "../../core/api/AppContext";

export const EmailTable: FunctionComponent = () => {
  const emailManagementPermission = usePermission(Function.Email);
  const dispatch = useDispatch();
  const { emails, searchParams } = useAppSelector((state) => state.email);

  const handleEdit = (email: Email): void => {
    dispatch(emailSlice.actions.setEditingEmail(email));
    dispatch(emailSlice.actions.toggleEditModal());
  };

  const handleDelete = (email: Email): void => {
    Modal.confirm({
      title: "刪除 Email",
      icon: <ExclamationCircleOutlined />,
      content: `確定要刪除 Email「${email.address}」？`,
      onOk() {
        AppContext.ApiExecutor.deleteEmail(email.id)
          .then(() => {
            notification.success({
              message: "刪除成功",
              placement: "bottomRight",
            });
            dispatch(refreshEmails());
          })
          .catch(() => {
            notification.warn({
              message: "刪除失敗",
              placement: "bottomRight",
            });
          });
      },
    });
  };

  const handleDisplay = (email: Email): void => {
    dispatch(emailSlice.actions.setDisplayEmail(email));
    dispatch(emailSlice.actions.toggleDisplayModal());
  };

  const onFilterChange = (
    config: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<Email> | SorterResult<Email>[]
  ) => {
    const orderBy = getOrderBy<Email>(sorter);
    dispatch(getEmails(config.current ?? 1, searchParams.filter, orderBy));
  };

  return (
    <>
      <Table<Email>
        tableLayout={"auto"}
        loading={emails.fetching}
        dataSource={emails.list}
        scroll={{ y: 800, x: 700 }}
        onChange={onFilterChange}
        pagination={{
          pageSize,
          showSizeChanger: false,
          total: emails.total,
          current: emails.page,
          showTotal: () => showTotalData(emails.total),
        }}
        rowKey={"id"}
      >
        <Table.Column<Email>
          title="Email Address"
          dataIndex="address"
          width={150}
          render={(name: string, data) => (
            <ResourceName onClick={() => handleDisplay(data)}>
              {name}
            </ResourceName>
          )}
        />
        <Table.Column
          title="總社名稱"
          dataIndex="headOffice"
          align={"center"}
          width={200}
        />
        <Table.Column
          title="說明"
          dataIndex="description"
          align={"center"}
          width={200}
        />
        <Table.Column
          sorter={true}
          title="更新人員"
          dataIndex="updatedUsername"
          width={150}
        />
        <Table.Column
          sorter={true}
          title="更新時間"
          dataIndex="updatedAt"
          width={200}
          align={"center"}
          render={(e: string) => formatTime(e)}
        />
        <Table.Column<Email>
          sorter={true}
          title="狀態"
          dataIndex="status"
          align={"center"}
          width={100}
          render={(status: number) => displayStatus(status)}
        />
        <Table.Column<Email>
          title="操作"
          dataIndex="id"
          align={"center"}
          width={100}
          render={(id, data) => {
            return (
              <TableActionWrapper>
                {emailManagementPermission.includes(Permission.Edit) && (
                  <EditOutlined onClick={() => handleEdit(data)} />
                )}
                {emailManagementPermission.includes(Permission.Delete) && (
                  <DeleteOutlined onClick={() => handleDelete(data)} />
                )}
              </TableActionWrapper>
            );
          }}
        />
      </Table>
    </>
  );
};
