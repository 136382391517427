import { useCallback, useEffect, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useDebounce, useToggle } from "react-use";
import { Channel } from "../types/Channel";
import { AppContext } from "../core/api/AppContext";
import { formatFuzzySearch } from "../utils/formatFuzzySearch";
import { formatSearchFilter } from "../utils/formatSearchFilter";
import { formatSearchStatus } from "../utils/formatSearchStatus";
import { Status } from "../constants/Status";

export const useChannels = (fetchAll = false) => {
  const [channels, setChannels] = useState<Channel[]>([]);
  const [loading, setLoading] = useToggle(false);
  const [page, setPage] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useToggle(false);
  const [name, setName] = useState<string>();

  const getChannels = useCallback(
    (reset?: boolean) => {
      setLoading(true);
      let params: any = {
        page: reset ? 1 : page,
      };

      if (!!name) {
        params["filter"] = formatSearchFilter([
          formatFuzzySearch(["name"], name),
          formatSearchStatus(Status.Active),
        ]);
      } else {
        params["filter"] = formatSearchFilter([
          formatSearchStatus(Status.Active),
        ]);
      }
      AppContext.ApiExecutor.getChannels(params)
        .then(({ data }) => {
          const lastPage = data.totalPages <= page;
          const nextPage = page + (lastPage ? 0 : 1);

          setChannels((prev) => {
            return [...prev, ...data.channels];
          });

          setPage(nextPage);
          setHasNextPage(!lastPage);
        })
        .catch((error) => console.warn(error))
        .finally(() => {
          setLoading(false);
        });
    },
    [page, name]
  );

  useEffect(() => {
    if (fetchAll && !loading && hasNextPage) {
      getChannels();
    }
  }, [loading]);

  const [loadingRef, { rootRef: loadingRootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: getChannels,
    rootMargin: "0px 0px 0px 0px",
  });

  const getChannelsByName = useCallback(
    (channelName?: string) => {
      setName(channelName);
    },
    [setName]
  );

  const refetchAll = useCallback(() => {
    setLoading(false);
    setPage(1);
    setHasNextPage(false);
    setName(undefined);
  }, []);

  const [, cancel] = useDebounce(
    () => {
      cancel();
      setChannels([]);
      getChannels(true);
    },
    500,
    [name]
  );

  return {
    channels,
    loading,
    loadingRef,
    loadingRootRef,
    hasNextPage,
    getChannelsByName,
    refetchAll,
  };
};
